import * as actionType from '../action'

const initialState = {
    user: JSON.parse(localStorage.getItem('user')),
};

const UserReducer = (state = initialState, action) => {
    switch (action.type) {
        // case actionType.ALL_USERS:{
        //     return { ...state, users: action.payload };
        // }
        case actionType.REGISTER:
            return { ...state, user: action.payload };
        case actionType.LOG_IN: {
            return { ...state, user: action.payload };
        }
        case actionType.LOG_OUT: {
            localStorage.removeItem('user')
            return { ...state, user: null };
        }

        default:
            return { ...state };

    }

}

export default UserReducer;
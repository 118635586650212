
import React, { useEffect, useState } from 'react';
import '../../style/ScoresTable.css';
import { ReactComponent as AddTextIcon } from '../../assets/frame_3.svg';
import { ReactComponent as SendMsgIcon } from '../../assets/icon.svg';
import { ReactComponent as CloseIcon } from '../../assets/close.svg';
import { ReactComponent as UpdateIcon } from '../../assets/update_svg.svg';
import { ReactComponent as OpenAvg } from '../../assets/open.svg';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';
import AvgScores from './avgScores';
import PopupSendMessage from './popups/popupSendMesssage';
import PopupSendComment from './popups/popupSendComment';
import { convertToCSV, deleteExamineeOfTest, downloadCSV, getUserExamQuestionsAndAnswers } from '../../Services/TestService';
import axios from 'axios';
import SideBar from './SideBar';
import { getUserByPhone } from '../../Services/UserService';
import PopupDeleteExaminee from './popups/popupDeleteExaminee';
import FileDownloadIcon from '@mui/icons-material/FileDownload'



const ScoresTable = () => {

    const [open, setOpen] = useState(false);
    const [openPopupComment, setOpenPopupComment] = useState(false);
    const [openDeletePopup,setOpenDeletePopup]=useState(false);
    const [clickExamineeId,setClickExamineeId]=useState(0);
    const [showSearchBar, setShowSearchBar] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [showAvgScores, SetShowAvgScores] = useState(true);
    const [editingRowId, setEditingRowId] = useState(null); // מצב עריכת השורה
    const [editedRow, setEditedRow] = useState({}); // ערכים מעודכנים
    const [rows, setRows] = useState([]);
    const [test, setTestId] = useState('');
    const [filterEasyLaw, setFilterEasyLaw] = useState(false);
    const frame = require('../../assets/Frame (2).jpg');
    const download = require('../../assets/download.jpg');
    const excel = require('../../assets/excell.jpg');
    const search = require('../../assets/Search.jpg');
    const easyLaw_logo = require('../../assets/mini-easylaw.jpg');
    const baseUrl = process.env.REACT_APP_SERVER_BASE_URL
    const [all, setAll] = useState(false);
    const testDisplay = localStorage.getItem('test');
    const testDetails = JSON.parse(testDisplay);


    useEffect(() => {
        setTestId(testDetails.testId);
        if (test) {
            fetchTestResults(test);
        }
    }, [test]);

    const fetchTestResults = async (test) => {
        try {
            console.log('Fetching data for testId:', test); // הוספת לוג לבדיקת testId
            const response = await axios.get(`${baseUrl}/Test/${test}/results`);
            console.log('Received data:', response.data); // הוספת לוג לתגובה מהשרת
            setRows(response.data);
        } catch (error) {
            console.error('Error fetching test results:', error);
        }
    };
    const handleChooseUser = (index) => {
        const phone = rows[index].phone
        console.log("res", phone)
        //todo
        return index
        // console.log(matchingUser);
    }


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpenCommentPopup = () => {
        setOpenPopupComment(true);
    };
    const handleCloseCommentPopup = () => {
        setOpenPopupComment(false);
    }

    const handleSearchComplete = () => {

        setShowSearchBar(false);
        setSearchTerm(''); // איפוס השדה

    };

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };


    const handleChangeShowAvg = () => {
        SetShowAvgScores(!showAvgScores);
    };


    const handleOpenSearchBar = () => {
        setShowSearchBar(true);
    };

    const filteredRows = rows.filter(row => {
        return (
            (!filterEasyLaw || row.isOurStudent) && // סינון לפי תלמידי EasyLaw
            (
                row.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                row.email?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                row.phone?.includes(searchTerm) ||
                row.isOurStudent?.toString().includes(searchTerm.toLowerCase()) ||
                row.proceduralScore?.toString().includes(searchTerm) ||
                row.substantiveScore?.toString().includes(searchTerm) ||
                row.writingTask?.toString().includes(searchTerm) ||
                row.endScore?.toString().includes(searchTerm)
            )
        );
    });

    const handleExportToExcel = () => {
        const tableContent = [
            ['שם', 'אימייל', 'טלפון', 'תלמיד איזילו', 'ציון דין דיוני', 'ציון דין מהותי', 'מטלת הכתיבה', 'ציון סופי'],
            ...rows.map(examinee => [examinee.name, examinee.email, examinee.phone, examinee.isOurStudent, examinee.proceduralScore, examinee.substantiveScore, examinee.writingTask, examinee.endScore])


        ];

        const csvContent1 = tableContent.map(row => row.join(',')).join('\n');
        const csvContent = "\uFEFF" + csvContent1;
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', 'examinees.csv');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }

        else {
            alert('Your browser does not support downloading files. Please try again with a different browser.');
        }
    };




    const updateTestResult = async (testId, userId, updatedResult) => {
        try {
            await axios.put(`${baseUrl}/Test/${testId}/results/${userId}`, updatedResult);
            const response = await axios.get(`${baseUrl}/Test/${testId}/results`);
            setRows(response.data);
        } catch (error) {
            console.error('Error updating test result:', error);
        }
    };

    const handleEditRow = (x, index) => {
        setEditingRowId(index);
        setEditedRow(x);
    }
    const handleToDeleteExaminee=async(index)=>{
       await setClickExamineeId(index);
       await setOpenDeletePopup(true);
    }

    const handleDeleteUser = async () => {
        const phone = rows[clickExamineeId].phone;
        const user = await getUserByPhone(phone);

        if (user && user.id) {
            // קריאה לפונקציית המחיקה עם testId ו-userId
            try {
                await deleteExamineeOfTest(test, user.id).then(async() => {
                    handleClosePopupDelete(); 
                    const response = await fetchTestResults(test);
                    setRows(response.data);
                })
            } catch (error) {
                console.error('Error deleting user:', error);
            }
        } else {
            console.error('User not found.');
        }
    }
    const handleSaveRow = async (index) => {
        const phone = rows[index].phone;
        const user = await getUserByPhone(phone);
        const editUserId = user.id;
        updateTestResult(test, editUserId, { ...editedRow, endScore: editedRow.endScore }); // שליחת הציון הסופי המחושב
        setEditingRowId(null);
        setEditedRow({});
    };


    const handleInputChange = (e, field, value) => {
        const newValue = value ?? e.target.value;


        const finalScore =
            (field === 'proceduralScore' ? parseInt(newValue, 10) : parseInt(editedRow.proceduralScore || 0, 10)) +
            (field === 'substantiveScore' ? parseInt(newValue, 10) : parseInt(editedRow.substantiveScore || 0, 10)) +
            (field === 'writingTask' ? parseInt(newValue, 10) : parseInt(editedRow.writingTask || 0, 10));

        setEditedRow((prevState) => ({
            ...prevState,
            [field]: newValue,
            endScore: finalScore,
        }));
    };
    // const totalPages = Math.ceil(filteredRows.length / rowsPerPage);
    const handleFilterIsOurStudent = () => {
        setAll(false); // ביטול הסימון של כל התלמידים
        setSearchTerm(''); // איפוס שורת החיפוש
        setRows(rows.filter(row => row.isOurStudent)); // סינון לפי תלמידי איזילו
    };


    const handleClosePopupDelete = () => {
        setOpenDeletePopup(false);
    };

// const handleDownload = async (index) => {
//     try {
//         const phone = rows[index].phone;
//         const user = await getUserByPhone(phone);
//         const editUserId = user.id;
//         const data = await getUserExamQuestionsAndAnswers(test, editUserId);
//         const csvContent = convertToCSV(data);
//         downloadCSV('exam_questions_and_answers.csv', csvContent);
//     } catch (error) {
//         console.error('Error downloading CSV:', error);
//     }
// };

const handleDownload = async (index) => {
    try {
      const phone = rows[index].phone;
      const user = await getUserByPhone(phone);
      const editUserId = user.id;
      const data = await getUserExamQuestionsAndAnswers(test, editUserId);
      const csvContent = convertToCSV(data);
  
      // יצירת קישור להורדה
      const link = document.createElement('a');
      link.href = 'data:text/csv;charset=utf-8,' + encodeURI(csvContent);
      link.target = '_blank';
      link.download = 'exam_questions_and_answers.csv';
  
      // הוספת הקישור ל-DOM והפעלת לחיצה עליו
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
  
    } catch (error) {
      console.error('שגיאה בהורדת CSV:', error);
    }
  };
    return (
        <>
            <div className='scores-s-t'>
                <div className="Frame1707478279-s-t sapir">
                    <h1 className='title-marks'>ציונים</h1>
                    <div className="Frame1707478253-s-t">
                        <div className="Frame1707478319-s-t">
                            <button className="Frame-s-t" onClick={() => handleChangeShowAvg()}>
                                {showAvgScores ? (<img src={frame} className="Vector-s-t" alt="Frame" />) : (<OpenAvg src={frame} className="Vector-s-t" alt="Frame" />)}
                            </button>
                            <div className="title-s-t">ממוצע ציונים</div>
                        </div>
                    </div>
                    {showAvgScores && (
                        <AvgScores testId={test} rows={rows} />
                    )}
                    <div className="Frame1707478423-s-t">
                        <div className="Frame1707478421-s-t">
                            <div className="Frame1707478422-s-t" onClick={handleOpenSearchBar}>
                                {showSearchBar ? (
                                    <div className="input-group-s-t"  >
                                        <input
                                            type="text"
                                            placeholder="...חפש משהו"
                                            className="search-input-s-t"
                                            value={searchTerm}

                                            onChange={handleSearch}
                                            onBlur={handleSearchComplete}
                                        />
                                        <button onClick={() => handleSearchComplete} className="ButtonComplete-s-t"><CloseIcon className="close-icon-s-t" /></button>
                                    </div>
                                ) : (
                                    <button className="ButtonSearch-s-t" onClick={handleOpenSearchBar}>
                                        ...חפש משהו
                                        <img src={search} className="IconSearch-s-t" />
                                    </button>

                                )}
                            </div>
                            <button className="ButtonFilterIsOurStudent-s-t" onClick={() => setFilterEasyLaw(!filterEasyLaw)}>
                                {filterEasyLaw ? 'כל התלמידים' : 'סינון לפי תלמידי Easylaw'}
                            </button>
                            <button className="IconGroup-s-t" onClick={() => { handleExportToExcel() }}>
                                <img src={download} className="IconDownload-s-t" />
                            </button>
                            <button className="ButtonAddFinish-s-t" onClick={handleOpenCommentPopup}>
                                הוספת הערת ציון
                                <AddTextIcon className="IconText-s-t" />
                            </button>
                            {openPopupComment && <div className='condition'><PopupSendComment open={openPopupComment} onClose={handleCloseCommentPopup} /></div>}
                            <button className="ButtonSendMessage-s-t" onClick={handleClickOpen}>
                                שליחת הודעה
                                <SendMsgIcon className="IconMsg-s-t" />
                            </button>
                            {open && <div className='condition'><PopupSendMessage open={open} onClose={handleClose} testId={test} /></div>}

                        </div>
                    </div>
                    <div className="table-container-s-t" >

                        <table className="styled-table-s-t">
                            <thead>
                                <tr className='Subjects-s-t'>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th>ציון סופי</th>
                                    <th>מטלת הכתיבה</th>
                                    <th>ציון דין מהותי</th>
                                    <th>ציון דין דיוני</th>
                                    <th>תלמיד איזילו </th>
                                    <th>טלפון</th>
                                    <th>אימייל</th>
                                    <th>שם</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredRows
                                    // .slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage)
                                    .map((row, index) => (
                                        <tr className='TableCell-s-t' key={index}>
                                            <td>
                                                {editingRowId === index ? (
                                                    <button className='btn-update' onClick={() => handleSaveRow(index)}>
                                                        שמירה
                                                    </button>
                                                ) : (
                                                    <div onClick={() => handleEditRow(row, index)}>
                                                        <UpdateIcon className="update-icon-s-t" />
                                                    </div>
                                                )}
                                            </td>
                                            <td>
                                                <IconButton onClick={() =>handleToDeleteExaminee(index) }>
                                                    <DeleteIcon className="delete-icon" />
                                                </IconButton>
                                            </td>
                                            <td>
                                                <IconButton>
                                                    <FileDownloadIcon style={{  color: 'rgba(5, 19, 92, 0.966)' }} onClick={()=>handleDownload(index)}/>
                                                </IconButton>
                                            </td>
                                            <td>
                                                {
                                                    row.endScore
                                                }
                                            </td>

                                            <td>
                                                {editingRowId === index ? (
                                                    <input type="number" value={editedRow.writingTask || row.writingTask} className='input-field' onChange={(e) => handleInputChange(e, 'writingTask')} />
                                                ) : (
                                                    row.writingTask
                                                )}
                                            </td>
                                            <td>
                                                {editingRowId === index ? (
                                                    <input type="number" value={editedRow.substantiveScore || row.substantiveScore} className='input-field' onChange={(e) => handleInputChange(e, 'substantiveScore')} />
                                                ) : (
                                                    row.substantiveScore
                                                )}
                                            </td>
                                            <td>
                                                {editingRowId === index ? (
                                                    <input type="number" value={editedRow.proceduralScore || row.proceduralScore} className='input-field' onChange={(e) => handleInputChange(e, 'proceduralScore')} />
                                                ) : (
                                                    row.proceduralScore
                                                )}
                                            </td>
                                            <td>
                                                {editingRowId === index ? (
                                                    <input
                                                        type="checkbox"
                                                        checked={editedRow.isOurStudent ?? row.isOurStudent} // השתמש ב-checked כדי להציג את המצב הנוכחי של הצ'קבוקס
                                                        className='input-field'
                                                        onChange={(e) => handleInputChange(e, 'isOurStudent', e.target.checked)} // העבר את הערך הנוכחי של הצ'קבוקס לפונקציה
                                                    />
                                                ) : (
                                                    row.isOurStudent && <img className="easylaw-logo-s-t" src={easyLaw_logo} alt="EasyLaw Logo" />
                                                )}
                                            </td>

                                            <td>
                                                {editingRowId === index ? (
                                                    <input type="tel" value={editedRow.phone || row.phone} className='input-field' onChange={(e) => handleInputChange(e, 'phone')} />
                                                ) : (
                                                    row.phone
                                                )}
                                            </td>
                                            <td style={{ textAlign: "right" }}>
                                                {editingRowId === index ? (
                                                    <input type="email" value={editedRow.email || row.email} className='input-field' onChange={(e) => handleInputChange(e, 'email')} />
                                                ) : (
                                                    row.email
                                                )}
                                            </td>
                                            <td style={{ textAlign: "right" }}>
                                                {editingRowId === index ? (
                                                    <input type="text" value={editedRow.name || row.name} className='input-field' onChange={(e) => handleInputChange(e, 'name')} />
                                                ) : (
                                                    row.name
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                    {openDeletePopup && (
                        <div className="div-openDeletePopup">
                            <PopupDeleteExaminee  onClose={handleClosePopupDelete} onConfirm={handleDeleteUser}/>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};


export default ScoresTable;

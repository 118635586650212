import React, { useState } from "react";
import '../../style/SideBar.css';
import { ReactComponent as ScoreIcon } from '../../assets/Frame.svg';
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import BarChartIcon from '@mui/icons-material/BarChart';

const SideBar = () => {
    const navigate = useNavigate();
    const location = useLocation(); // Use location to get the current path
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const return_icon = require('../../assets/return.png');
    const solution_icon = require('../../assets/solution.png');

    const handleGetScores = () => {
        navigate('/admin/scores');
    };

    const handleUpdateEqualQuestions = () => {
        navigate('/setting');
    };

    const handleOptionClick = () => {
        navigate('/admin/statistic');
    };

    // Retrieve the test details from localStorage and check if it exists
    const testDisplay = localStorage.getItem("test");
    let testDetails = null;

    if (testDisplay) {
        try {
            testDetails = JSON.parse(testDisplay);
        } catch (error) {
            console.error("Error parsing testDisplay:", error);
        }
    }

    return (
        <div className={`SideMenu-s-b ${isDropdownOpen ? 'expanded-s-b' : ''}`}>
            <div className="Frame1707478340-s-b">
                <div className="Frame1707478457-s-b">
                    <div
                        className={`Frame1707478340_inner-s-b ${location.pathname === '/admin/poolOfTest' ? 'selected-s-b' : ''}`}
                        onClick={() => navigate('/admin/poolOfTest')}
                    >
                        <div className="Text-s-b">מאגר מבחנים</div>
                        <img src={return_icon} className="IconAltArrowRight-s-b" />
                    </div>
                </div>
                <div className="side-bar">
                    {testDetails && testDetails.title && (
                        <div className="Frame1000002375-s-b">
                            <div className="Text large-s-b">{testDetails.title}</div>
                        </div>
                    )}
                </div>
                <div className="Frame1707478339-s-b">
                    <button
                        className={`SideMenuButton-s-b ${location.pathname === '/setting' ? 'selected-s-b' : ''}`}
                        onClick={handleUpdateEqualQuestions}
                    >
                        <span className="Label-s-b">התאמת שאלות</span>
                        <img src={solution_icon} className="Vector-t-b" alt="solution icon" />
                    </button>
                    <button
                        className={`SideMenuButton-s-b ${location.pathname === '/admin/statistic' ? 'selected-s-b' : ''}`}
                        onClick={handleOptionClick}
                    >
                        <span className="Label-s-b">פתרון וסטטיסטיקה</span>
                        <BarChartIcon className="Vector-t-b" alt="folder icon" />
                    </button>
                    <button
                        className={`SideMenuButton-s-b ${location.pathname === '/admin/scores' ? 'selected-s-b' : ''}`}
                        onClick={handleGetScores}
                    >
                        <span className="Label-s-b">ציונים</span>
                        <ScoreIcon className="Vector-t-b" />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default SideBar;

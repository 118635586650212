
// import React, { useEffect, useState } from 'react';
// import '../../style/TopBar.css'; // קובץ ה-CSS
// import { useLocation } from 'react-router-dom';
// import { ReactComponent as IconMadalya } from '../../assets/madalya.svg';
// import { ReactComponent as IconSolution } from '../../assets/solution.svg';
// import { ReactComponent as IconFolder } from '../../assets/folder.svg';
// import { useDispatch, useSelector } from 'react-redux';
// import { fetchSwitchValue, updateSwitchValue } from '../../Services/TestService';
// import Switch from '@mui/material/Switch'; // ייבוא Switch מממשק MUI
// import { styled } from '@mui/material/styles'; // ייבוא כלים לעיצוב מותאם אישית


// const TopBar = () => {
//   const logoImage = require('../../assets/easylaw-logo-01.jpg');
//   const arrow = require('../../assets/arrow-down.jpg');
//   const [userName, setUserName] = useState(null);
//   const [permissionType, setPermissionType] = useState('');
//   const [activeButton, setActiveButton] = useState(null);
//   const isSwitchActive = useSelector(state => state.Test.isSwitchActive);
//   const dispatch = useDispatch();

//   const handleToggleSwitch = () => {
//     const newValue = !isSwitchActive;
//     dispatch(updateSwitchValue('Switch', newValue));
//   };
//   const location = useLocation();
//   const userString = localStorage.getItem('user');
//   let userObject = null;

//   if (userString) {
//     userObject = JSON.parse(userString);

//   }
//   useEffect(() => {

//     dispatch(fetchSwitchValue('Switch'));
//   }, [dispatch]);

//   useEffect(() => {
//     switch (location.pathname) {
//       case '/admin/scores':
//         setActiveButton('ציונים');
//         break;
//       case '/admin/poolOfTest':
//         setActiveButton('מאגר');
//         break;
//       case '/setting':
//         setActiveButton('התאמת שאלות');
//       case '/admin/statistic':
//         setActiveButton('פתרון ')
//         break;
//       default:
//         setActiveButton(null);
//     }
//   }, [location.pathname]); // הרצת useEffect בכל שינוי של הנתיב



//   useEffect(() => {
//     const fetchUser = async () => {

//       const userFromStorage = localStorage.getItem("user");
//       if (userFromStorage) {
//         const parsedUser = JSON.parse(userFromStorage);
//         setUserName(parsedUser.name);
//         setPermissionType(parsedUser.permission.name)

//       }
//     };
//     fetchUser();
//   }, [])

//   const user = localStorage.getItem('user')
//   // עיצוב מותאם אישית לסוויץ' של MUI
//   const CustomSwitch = styled(Switch)(({ theme }) => ({
//     '& .MuiSwitch-switchBase': {
//       '&.Mui-checked': {
//         color: '#17C2D0', // צבע פעיל
//       },
//       '&.Mui-checked + .MuiSwitch-track': {
//         backgroundColor: '#17C2D0', // צבע רקע כאשר פעיל
//       },
//     },
//     '& .MuiSwitch-track': {
//       backgroundColor: '#013B82', // צבע רקע כאשר מכובה
//     },
//   }));

//   return (
//     <>
//       {(userObject) && (userObject.permission) && (userObject.permission.name === "SuperAdmin" || userObject.permission.name === "Admin") &&
//         (
//           <div className="Frame1707478335-t-b">
//             <div className="Frame21-t-b">
//               <img className="EasylawLogo011-t-b" src={logoImage} alt="Easylaw Logo" />
//               <div className='title_top right-side-title'>Easylaw - Grades</div>
//             </div>

//             <div className="Frame1707478442-t-b">
//               <div className="Frame1707478345-t-b">
//                 <div className="SolutionText-t-b">הפתרון שלנו מוכן</div>
//                 <div className="Switch-t-b">
//                   <CustomSwitch
//                     checked={isSwitchActive}
//                     onChange={handleToggleSwitch}
//                     color="default"
//                   />
//                 </div>
//               </div>
//             </div>

//             <div className="Frame1208-t-b">
//               <div className="Frame603-t-b">
//                 <div className="Group602-t-b">
//                   <div className="Name-t-b">{userName}</div>
//                   <div className="SuperAdmin-t-b">{permissionType}</div>
//                 </div>
//                 <div className="VuesaxOutlineArrowDown-t-b">
//                   <div className="ArrowDown-t-b">
//                     <img src={arrow} className="ArrowDown-t-b" alt="Arrow Down" />
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         )}
//     </>
//   )
// };

// export default TopBar;


import React, { useEffect, useState } from 'react';
import '../../style/TopBar.css'; // CSS file
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSwitchValue, updateSwitchValue } from '../../Services/TestService';
import Switch from '@mui/material/Switch'; // MUI Switch import
import { styled } from '@mui/material/styles'; // Custom styling tools
import LogoutIcon from '@mui/icons-material/Logout'; // Importing Logout icon
import GroupIcon from '@mui/icons-material/Group';
import PopupLogout from './popups/popupLogOut';
import downArrowIcon from '../../assets/down-arrow.jpg';

const TopBar = () => {
  const arrow = require('../../assets/arrow-down.jpg');
  const logoImage = require('../../assets/easylaw-logo-01.jpg');
  const arrow_right = require('../../assets/Alt Arrow Right.png');
  const [isLogoutPopupOpen, setLogoutPopupOpen] = useState(false);
  const [userName, setUserName] = useState(null);
  const [permissionType, setPermissionType] = useState('');
  const [activeButton, setActiveButton] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false); // New state for dropdown
  const isSwitchActive = useSelector(state => state.Test.isSwitchActive);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleToggleSwitch = () => {
    const newValue = !isSwitchActive;
    dispatch(updateSwitchValue('Switch', newValue));
  };

  const location = useLocation();
  const userString = localStorage.getItem('user');
  let userObject = null;

  if (userString) {
    userObject = JSON.parse(userString);
  }

  useEffect(() => {
    dispatch(fetchSwitchValue('Switch'));
  }, [dispatch]);

  useEffect(() => {
    switch (location.pathname) {
      case '/admin/scores':
        setActiveButton('ציונים');
        break;
      case '/admin/poolOfTest':
        setActiveButton('מאגר');
        break;
      case '/setting':
        setActiveButton('התאמת שאלות');
        break;
      case '/admin/statistic':
        setActiveButton('פתרון');
        break;
      default:
        setActiveButton(null);
    }
  }, [location.pathname]);

  useEffect(() => {
    const fetchUser = async () => {
      const userFromStorage = localStorage.getItem('user');
      if (userFromStorage) {
        const parsedUser = JSON.parse(userFromStorage);
        setUserName(parsedUser.name);
        setPermissionType(parsedUser.permission.name);
      }
    };
    fetchUser();
  }, []);

  const CustomSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase': {
      '&.Mui-checked': {
        color: '#17C2D0',
      },
      '&.Mui-checked + .MuiSwitch-track': {
        backgroundColor: '#17C2D0',
      },
    },
    '& .MuiSwitch-track': {
      backgroundColor: '#013B82',
    },
  }));

  const handleDropdownToggle = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleLogout = () => {
    localStorage.clear();
    navigate('/login');
  };
  const handleNavigateToTeam = () => {
    navigate('/all-admin');
    setDropdownOpen(false); // Hide dropdown after selection
};

const handleLogoutClick = () => {
    setLogoutPopupOpen(true);
    setDropdownOpen(false); // Hide dropdown after selection
};

  const handleNavigateToTests = () => {
    navigate('/admin/poolOfTest');
  };

  const closeLogoutPopup = () => {
    setLogoutPopupOpen(false);
  };

  return (
    <>
      {userObject && userObject.permission && (userObject.permission.name === 'SuperAdmin' || userObject.permission.name === 'Admin') && (
        <div className="Frame1707478335-t-b">
          <div className="Frame21-t-b">
            <img className="EasylawLogo011-t-b" src={logoImage} alt="Easylaw Logo" />
            <div className="title_top right-side-title">Easylaw - Grades</div>
          </div>

          <div className="Frame1707478442-t-b">
            <div className="Frame1707478345-t-b">
              <div className="SolutionText-t-b">הפתרון שלנו מוכן</div>
              <div className="Switch-t-b">
                <CustomSwitch checked={isSwitchActive} onChange={handleToggleSwitch} color="default" />
              </div>
            </div>
          </div>

          <div className="Frame1208-t-b">
            <div className="Frame603-t-b">
              <div className="Group602-t-b">
                <div className="Name-t-b">{userName}</div>
                <div className="SuperAdmin-t-b">{permissionType}</div>
              </div>

              <div className="VuesaxOutlineArrowDown-t-b" onClick={handleDropdownToggle}>
                <img src={dropdownOpen ? arrow_right : downArrowIcon} className="ArrowDown-t-b" alt="Arrow" />
              </div>

              {dropdownOpen && (
                <div className="DropdownMenu">
                  <div className="DropdownItem" onClick={handleNavigateToTeam}>
                    <GroupIcon className="TeamIcon" style={{ fontSize: '18px' }} />
                    צוות EasyLaw
                  </div>
                  <div className="DropdownItem" onClick={handleLogoutClick}>
                    <LogoutIcon className="LogoutIcon" style={{ fontSize: '18px' }} />
                    התנתקות
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
       <PopupLogout
        open={isLogoutPopupOpen}
        onClose={closeLogoutPopup}
        onConfirm={handleLogout}
      />
    </>
  );
};

export default TopBar;



// import React, { useEffect, useState } from 'react';
// import '../../style/RecieveMark.css'; // Include your CSS file
// import {  getCommentForLatestTestAndUserId } from '../../Services/UserService';
// import { fetchSwitchValue, fetchTests, getEndScore, updateScores } from '../../Services/TestService';
// import { useDispatch, useSelector } from 'react-redux';


// const RecieveMark = () => {

//     const [score, setScore] = useState(null);
//     const [userName, setUserName] = useState(null);
//     const [userId, setUserId] = useState(null);
//     const [comment, setComment] = useState(null);
//     const logoImage = require('../../assets/easylaw-logo-01.jpg');
//     const cloud_right = require('../../assets/annan.jpg');
//     const cloud_left = require('../../assets/annan.jpg'); // Duplicate cloud image for left side
//     const book = require('../../assets/Book (1).jpg');

//     const versionOfDiscussion = parseInt(localStorage.getItem('versionOfDiscussion'), 10);
//     const versionOfSubstantive = parseInt(localStorage.getItem('versionOfSubstantive'), 10);
//     const dispatch = useDispatch();
//     const Alltests = useSelector(state => state.Test.tests);


//     useEffect(() => {
//         dispatch(fetchTests());
//     }, [dispatch]);

//     const latestActiveTest = Alltests
//         .filter(test => test.isActive)
//         .sort((a, b) => new Date(b.dateOfTest) - new Date(a.dateOfTest))[0];
//     useEffect(() => {
//         dispatch(fetchTests());
//     }, [dispatch]);


//     const fetchScore = async (latestActiveTest, userId) => {
//         try {
//             await updateScores({ UserId: userId, TestId: latestActiveTest.id })
//             const endScore = await getEndScore(userId,latestActiveTest.id);
//             setScore(Number(endScore)); // עדכון ה-state עם הציון הסופי בלבד
//         } catch (error) {
//             console.error('Failed to fetch score:', error);
//         }
//     };
    
 

//     useEffect(()=>{
//         const fetchUser = async() => {
//             const userFromStorage = localStorage.getItem("user");
//             if (userFromStorage) {
//                 const parsedUser = JSON.parse(userFromStorage);
//                 await setUserName(parsedUser.name)
//                 await setUserId(parsedUser.id);
               
//             }
//         };
//         const fetchComment = async () => {
//             if (userId) {
//                 const fetchedComment = await getCommentForLatestTestAndUserId(userId);
//                 setComment(fetchedComment);
//             }
//         };
//         fetchUser().then(()=>{
//             fetchComment();
//         })

//     }, [userId]);

//     useEffect(() => {
//         dispatch(fetchSwitchValue()); // הבאת הערך הנוכחי של ה־switch
//       }, []);

//       const isSwitchActive = useSelector(state => state.Test.isSwitchActive);
//     useEffect(() => {
//         if (userId && isSwitchActive) {
//             fetchScore(latestActiveTest, userId);
//         }
//     }, [latestActiveTest, userId]);


//     const proceduralVersion = localStorage.getItem("proceduralVersion");
//     const substantiveVersion = localStorage.getItem("substantiveVersion"); 
//         return (
//             <div className="container-m">
//                 <div className="header-m">
//                     <img className="logo-m" src={logoImage} alt="Logo" />
//                 </div>
//                 <div className="content-m">
//                     <div className="score-container-m">
//                         <div className="score-background-m">
//                             <img src={cloud_left} alt="ענן" className="cloud-m cloud-left-m" />
//                             <img src={cloud_right} alt="ענן" className="cloud-m cloud-right-m" />
//                             <div className="score-box-m" />
//                             <div className="score-details-m">
//                                 <img src={book} alt="ספר" className="book-m" />
//                             </div>
//                         </div>
//                     </div>
//                     <div className="main-score-m">
//                         <div className="greeting-m">הי, {userName}</div>
//                         <div className="estimated-score-m">הציון המשוער שלך הוא:</div>
//                         <div className="score-m">{score}</div>
//                         <div className="test-versions-m">
//                            <div className='txt-version'>גירסת המבחן שלך בדין דיוני היא: <strong>{window.location.pathname==='/register'? versionOfDiscussion:proceduralVersion}</strong></div>
//                             <div className='txt-version'>גירסת המבחן שלך בדין מהותי היא: <strong>{window.location.pathname==='/register'? versionOfSubstantive:substantiveVersion}</strong></div> 
//                         </div>
//                     </div>
//                     {comment && (
//                         <>
//                             <div className="help-box-m" dangerouslySetInnerHTML={{ __html: comment }} />
//                             <div className="details-link-m">
                               
//                             </div>
//                         </>
//                     )}
//                 </div>
//             </div>
//         );
// };

// export default RecieveMark;


// import React, { useEffect, useState } from 'react';
// import '../../style/RecieveMark.css'; // Include your CSS file
// import { getCommentForLatestTestAndUserId } from '../../Services/UserService';
// import { convertToCSV, downloadCSV, fetchSwitchValue, fetchTests, getEndScore, getUserExamQuestionsAndAnswers, updateScores } from '../../Services/TestService';
// import { useDispatch, useSelector } from 'react-redux';

// const RecieveMark = () => {
//     const [score, setScore] = useState(null);
//     const [displayedScore, setDisplayedScore] = useState(0); // Score to display with animation
//     const [userName, setUserName] = useState(null);
//     const [userId, setUserId] = useState(null);
//     const [comment, setComment] = useState(null);
//     const logoImage = require('../../assets/easylaw-logo-01.jpg');
  
//     const book = require('../../assets/Group 1.png');

//     const versionOfDiscussion = parseInt(localStorage.getItem('versionOfDiscussion'), 10);
//     const versionOfSubstantive = parseInt(localStorage.getItem('versionOfSubstantive'), 10);
//     const dispatch = useDispatch();
//     const Alltests = useSelector(state => state.Test.tests);

//     useEffect(() => {
//         dispatch(fetchTests());
//     }, [dispatch]);

//     const latestActiveTest = Alltests
//         .filter(test => test.isActive)
//         .sort((a, b) => new Date(b.dateOfTest) - new Date(a.dateOfTest))[0];

//     const fetchScore = async (latestActiveTest, userId) => {
//         try {
//             await updateScores({ UserId: userId, TestId: latestActiveTest.id });
//             const endScore = await getEndScore(userId, latestActiveTest.id);
//             setScore(Number(endScore));
//         } catch (error) {
//             console.error('Failed to fetch score:', error);
//         }
//     };

//     useEffect(() => {
//         const fetchUser = async () => {
//             const userFromStorage = localStorage.getItem("user");
//             if (userFromStorage) {
//                 const parsedUser = JSON.parse(userFromStorage);
//                 await setUserName(parsedUser.name);
//                 await setUserId(parsedUser.id);
//             }
//         };

//         const fetchComment = async () => {
//             if (userId) {
//                 const fetchedComment = await getCommentForLatestTestAndUserId(userId);
//                 setComment(fetchedComment);
//             }
//         };

//         fetchUser().then(() => {
//             fetchComment();
//         });
//     }, [userId]);

//     useEffect(() => {
//         dispatch(fetchSwitchValue('Switch')); // Fetch current switch value
//     }, [dispatch]);

//     const isSwitchActive = useSelector(state => state.Test.isSwitchActive);

//     useEffect(() => {
//         if (userId && isSwitchActive) {
//             fetchScore(latestActiveTest, userId);
//         }
//     }, [latestActiveTest, userId]);

//     // Animation effect for score display
//     useEffect(() => {
//         if (score !== null) {
//             let currentScore = 1;
//             const increment = Math.ceil(score / 50); // Define speed of the animation
//             const interval = setInterval(() => {
//                 if (currentScore >= score) {
//                     clearInterval(interval);
//                     setDisplayedScore(score); // Set the final score
//                 } else {
//                     currentScore += increment;
//                     setDisplayedScore(currentScore);
//                 }
//             }, 20); // Adjust time interval for speed
//         }
//     }, [score]);
//     // const handleDownload = async (userId) => {
//     //     try {
//     //         const data = await getUserExamQuestionsAndAnswers(latestActiveTest.id, userId);
//     //         const csvContent = convertToCSV(data);
//     //         downloadCSV('exam_questions_and_answers.csv', csvContent);
//     //     } catch (error) {
//     //         console.error('Error downloading CSV:', error);
//     //     }
//     // };
//     const handleDownload = async (userId) => {
//         try {
//             const data = await getUserExamQuestionsAndAnswers(latestActiveTest.id, userId);
//             const csvContent = convertToCSV(data);
    
//             const blob = new Blob([csvContent], { type: 'text/csv' });
//             const url = window.URL.createObjectURL(blob);
            
//             // Open the file in a new window/tab on mobile devices
//             if (window.navigator && window.navigator.msSaveOrOpenBlob) {
//                 window.navigator.msSaveOrOpenBlob(blob, 'exam_questions_and_answers.csv');
//             } else if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
//                 // For mobile devices, open in a new tab
//                 const newWindow = window.open(url);
//                 if (!newWindow) {
//                     alert('לא ניתן לפתוח את הקובץ. נא לוודא שהדפדפן תומך.');
//                 }
//             } else {
//                 const link = document.createElement('a');
//                 link.href = url;
//                 link.setAttribute('download', 'exam_questions_and_answers.csv');
//                 document.body.appendChild(link);
//                 link.click();
//                 document.body.removeChild(link);
//             }
//         } catch (error) {
//             console.error('Error downloading CSV:', error);
//         }
//     };
    
//     const proceduralVersion = localStorage.getItem("proceduralVersion");
//     const substantiveVersion = localStorage.getItem("substantiveVersion");

//     return (
//         <div className="container-m">
//             <div className="header-m">
//                 <img className="logo-m" src={logoImage} alt="Logo" />
//             </div>
//             <div className="content-m">
//                 <img src={book} alt="ספר"  />

//                 <div className="main-score-m">
//                     <div className="greeting-m">הי, {userName}</div>
//                     <div className="estimated-score-m">הציון המשוער שלך</div>
//                     <h3 className='title-score-m'>בשקלול הדין הדיוני והדין המהותי הוא:</h3>
//                     <div className="score-m">{displayedScore}</div>
//                     <div className="test-versions-m">
//                         <div className='txt-version'>גירסת המבחן שלך בדין דיוני היא: <strong>{window.location.pathname === '/register' ? versionOfDiscussion : proceduralVersion}</strong></div>
//                         <div className='txt-version'>גירסת המבחן שלך בדין מהותי היא: <strong>{window.location.pathname === '/register' ? versionOfSubstantive : substantiveVersion}</strong></div>
//                     </div>
//                 </div>
//                 <button className='downCsv' onClick={()=>handleDownload(userId)}>לפתרונות שלך</button>
//                 {comment && (
//                     <>
//                         <div className="help-box-m" dangerouslySetInnerHTML={{ __html: comment }} />
//                         <div className="details-link-m"></div>
//                     </>
//                 )}
//             <div className="home-link-m" onClick={() => window.location.href = "https://easylaw.io"}> לדף הבית &gt;&gt; </div>

            
//             </div>
          

//         </div>
//     );
// };

// export default RecieveMark;


import React, { useEffect, useState } from 'react';
import '../../style/RecieveMark.css';
import { getCommentForLatestTestAndUserId } from '../../Services/UserService';
import { convertToCSV, downloadCSV, fetchSwitchValue, fetchTests, getEndScore, getUserExamQuestionsAndAnswers, updateScores } from '../../Services/TestService';
import { useDispatch, useSelector } from 'react-redux';

const RecieveMark = () => {
    const [score, setScore] = useState(null);
    const [displayedScore, setDisplayedScore] = useState(0);
    const [userName, setUserName] = useState(null);
    const [userId, setUserId] = useState(null);
    const [comment, setComment] = useState(null);
    const logoImage = require('../../assets/easylaw-logo-01.jpg');
    const book = require('../../assets/Group 1.png');
  
    const versionOfDiscussion = parseInt(localStorage.getItem('versionOfDiscussion'), 10);
    const versionOfSubstantive = parseInt(localStorage.getItem('versionOfSubstantive'), 10);
    const dispatch = useDispatch();
    const Alltests = useSelector(state => state.Test.tests);

    useEffect(() => {
        dispatch(fetchTests());
    }, [dispatch]);

    const latestActiveTest = Alltests
        .filter(test => test.isActive)
        .sort((a, b) => new Date(b.dateOfTest) - new Date(a.dateOfTest))[0];

    const fetchScoreAndComment = async () => {
        if (userId && latestActiveTest) {
            try {
                await updateScores({ UserId: userId, TestId: latestActiveTest.id });
                const endScore = await getEndScore(userId, latestActiveTest.id);
                setScore(Number(endScore));

                // Fetch comment after setting score
                const fetchedComment = await getCommentForLatestTestAndUserId(userId);
                setComment(fetchedComment);
            } catch (error) {
                console.error('Failed to fetch score or comment:', error);
            }
        }
    };

    useEffect(() => {
        const fetchUser = async () => {
            const userFromStorage = localStorage.getItem("user");
            if (userFromStorage) {
                const parsedUser = JSON.parse(userFromStorage);
                setUserName(parsedUser.name);
                setUserId(parsedUser.id);
            }
        };
        fetchUser();
    }, []);

    useEffect(() => {
        if (userId && latestActiveTest) {
            fetchScoreAndComment();
        }
    }, [userId, latestActiveTest]);

    useEffect(() => {
        if (score !== null) {
            let currentScore = 1;
            const increment = Math.ceil(score / 50); 
            const interval = setInterval(() => {
                if (currentScore >= score) {
                    clearInterval(interval);
                    setDisplayedScore(score); 
                } else {
                    currentScore += increment;
                    setDisplayedScore(currentScore);
                }
            }, 20);
        }
    }, [score]);

    const handleDownload = async (userId) => {
        try {
            const data = await getUserExamQuestionsAndAnswers(latestActiveTest.id, userId);
            const csvContent = convertToCSV(data);
    
            const blob = new Blob([csvContent], { type: 'text/csv' });
            const url = window.URL.createObjectURL(blob);
            
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(blob, 'exam_questions_and_answers.csv');
            } else if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
                const newWindow = window.open(url);
                if (!newWindow) {
                    alert('לא ניתן לפתוח את הקובץ. נא לוודא שהדפדפן תומך.');
                }
            } else {
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'exam_questions_and_answers.csv');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        } catch (error) {
            console.error('Error downloading CSV:', error);
        }
    };

    const proceduralVersion = localStorage.getItem("proceduralVersion");
    const substantiveVersion = localStorage.getItem("substantiveVersion");

    return (
        <div className="container-m">
            <div className="header-m">
                <img className="logo-m" src={logoImage} alt="Logo" />
            </div>
            <div className="content-m">
                <img src={book} alt="ספר" />

                <div className="main-score-m">
                    <div className="greeting-m">הי, {userName}</div>
                    <div className="estimated-score-m">הציון המשוער שלך</div>
                    <h3 className='title-score-m'>בשקלול הדין הדיוני והדין המהותי הוא:</h3>
                    <div className="score-m">{displayedScore}</div>
                    <div className="test-versions-m">
                        <div className='txt-version'>גירסת המבחן שלך בדין דיוני היא: <strong>{window.location.pathname === '/register' ? versionOfDiscussion : proceduralVersion}</strong></div>
                        <div className='txt-version'>גירסת המבחן שלך בדין מהותי היא: <strong>{window.location.pathname === '/register' ? versionOfSubstantive : substantiveVersion}</strong></div>
                    </div>
                </div>
                <button className='downCsv' onClick={() => handleDownload(userId)}>לפתרונות שלך</button>
                {comment && (
                    <div className="help-box-m" dangerouslySetInnerHTML={{ __html: comment }} />
                )}
                <div className="home-link-m" onClick={() => window.location.href = "https://easylaw.io"}>לדף הבית &gt;&gt;</div>
            </div>
        </div>
    );
};

export default RecieveMark;

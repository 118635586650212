

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addTestToUser, getExamVersionsByPhone, loginUser, sendEmailVerificationCode, verifySmsCode } from '../../Services/UserService';
import { useNavigate } from 'react-router-dom';
import { fetchSwitchValue, updateScores } from '../../Services/TestService';
import {useLocation } from 'react-router-dom';



const VerifySmsPopup = ({ phone, setShowPopup, onError }) => {
    const [code, setCode] = useState(['', '', '', '']); // array for the verification code
    const [email, setEmail] = useState('');
    const [showEmailInput, setShowEmailInput] = useState(false); // to display email input
    const [showSmsInput, setShowSmsInput] = useState(false); // to display phone input
    const [isEmailVerification, setIsEmailVerification] = useState(false); // to know if it's email verification
    const [phoneInput, setPhoneInput] = useState(phone); // state for phone input
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location =useLocation();
    const Alltests = useSelector(state => state.Test.tests);


    useEffect(() => {
        dispatch(fetchSwitchValue('Switch')); // fetch the switch value
    }, [dispatch]);

    const handleChange = (index, value) => {
        if (/^\d$/.test(value) || value === '') { // check that the value is a digit or empty
            const newCode = [...code];
            newCode[index] = value; // set the digit at the correct index
            setCode(newCode);

            if (value !== '' && index < 3) { // if not empty and not at the end
                document.getElementById(`digit-${index + 1}`).focus(); // move focus to the next input
            }

            if (newCode.every(digit => digit !== '')) {
                handleVerifyCode(newCode.join('')); // verify once all digits are filled
            }
        }
    };

    useEffect(() => {
        document.getElementById('digit-0').focus(); // start focus on the leftmost input
    }, [dispatch]);

    const isSwitchActive = useSelector(state => state.Test.isSwitchActive);

    const handleFetchExamVersions = async () => {
        const response = await getExamVersionsByPhone(phoneInput);
        localStorage.setItem("proceduralVersion", response.proceduralVersion);
        localStorage.setItem("substantiveVersion", response.substantiveVersion);
    };

    const resetVerificationState = () => {
        // Reset the verification code input
        setCode(['', '', '', '']);

        // Show the previous input (email or phone) based on the verification method
        if (isEmailVerification) {
            setShowEmailInput(true);
        } else {
            setShowSmsInput(true);
        }

        // Close the current verification popup
        setShowPopup(false);
    };
    const handleVerifyCode = async (verificationCode) => {
        try {
            const verificationResponse = isEmailVerification
                ? await verifySmsCode(email, verificationCode)
                : await verifySmsCode(phoneInput, verificationCode);

            const user = verificationResponse.data.user;

            if (user.permissionId === 1 || user.permissionId === 2) {
                navigate('/admin/poolOfTest');
            } else {
                await handleAddTestToUser(user);
            }
            setShowPopup(false);
            localStorage.setItem("token", verificationResponse.data.accessToken);
            localStorage.setItem("user", JSON.stringify(user));
        } catch (error) {
            onError();
            if (location.pathname === 'register') {
                navigate('/register');
            } else if (location.pathname === 'login') {
                navigate('/login');
            }
        }
    };

    const handleCalculateScore = () => {
        const user = JSON.parse(localStorage.getItem("user"));
        const latestTestId = Alltests.length > 0 ? Alltests[Alltests.length - 1].Id : null;
        updateScores({ UserId: user.Id, TestId: latestTestId });
    };

    const handleSendEmailCode = async () => {
        try {
            const response = await sendEmailVerificationCode(email);

            if (response.status >= 200 && response.status < 300) {
                setIsEmailVerification(true); // switch to email verification
                setShowEmailInput(false); // hide email input
            } else {
                alert("Failed to send verification code to email.");
            }
        } catch (error) {
            console.error("Error sending email verification code:", error);
        }
    };

    const handleSendSmsCode = async () => {
        setPhoneInput(phoneInput); // Set phone input to the user-entered phone number
        await handleSubmit(); // Directly call handleSubmit to trigger the SMS code sending and open the popup
    };
    const handleAddTestToUser = async (user) => {
        const userId = user.id;
        const versionOfDiscussion = parseInt(localStorage.getItem('versionOfDiscussion'), 10);
        const versionOfSubstantive = parseInt(localStorage.getItem('versionOfSubstantive'), 10);
        const discussionQuestions = JSON.parse(localStorage.getItem('discussionQuestions') || '[]').map(Number);
        const substantiveQuestions = JSON.parse(localStorage.getItem('substantiveQuestions') || '[]').map(Number);
        const response = await dispatch(addTestToUser(userId, versionOfDiscussion, versionOfSubstantive, discussionQuestions, substantiveQuestions));

        if (window.location.pathname === '/login') {
            if (isSwitchActive) {
                await handleFetchExamVersions();
                navigate('/user/test/mark');
            } else {
                navigate('/user/test/finish', { state: { isSolutionReady: false } });
            }
        }

        if (response.message === 'User successfully added to the test with answers.') {
            if (window.location.pathname === '/register') {
                if (isSwitchActive) {
                    await handleFetchExamVersions();
                    await handleCalculateScore();
                    navigate('/user/test/mark');
                } else {
                    navigate('/user/test/finish', { state: { isSolutionReady: false } });
                    console.log('Test successfully added');
                }
            }
        }
    };

    const handleSubmit = async () => {
        const response = await loginUser(phone)

        if (response?.message == "Verification code sent successfully.") {
            setIsEmailVerification(false);
            setShowSmsInput(false); // hide email input
        }
        else {
            if ((response?.message == "You are not registered. Please register first.") ||
                (response?.message == "Your registration has expired. Please register again.")) {
                navigate('/register');
            } else {
                alert('ההתחברות נכשלה');
            }
        }

    };
    return (
        <div className="Sms-v-s-p" style={{ direction: 'rtl' }}>
            <div className="Title-v-s-p">
                <div className="TitleText-v-s-p">התחברות</div>
            </div>

            {!showEmailInput && !showSmsInput ? (
                <>
                    <div className="Title-v-s-p">
                        <div className="SmsMessage-v-s-p">
                            {!isEmailVerification ? (
                                <>
                                    <div className='txt-v-s-p'>ברגע זה נשלחה אליך הודעת sms עם קוד אימות,</div>
                                    <br />
                                    <div className='txt-v-s-p'>נא להקליד את הקוד שהתקבל</div>
                                </>
                            ) : (
                                <>
                                    <div className='txt-v-s-p'>ברגע זה נשלחה אליך הודעת מייל עם קוד אימות,</div>
                                    <br />
                                    <div className='txt-v-s-p'>נא להקליד את הקוד שהתקבל</div>
                                </>
                            )}
                        </div>
                    </div>

                    <div className="VerificationCode-v-s-p">
                        {code.map((digit, index) => (
                            <input
                                key={index}
                                id={`digit-${index}`}
                                className="VerificationInput-v-s-p"
                                type="text"
                                maxLength="1"
                                value={digit}
                                onChange={(e) => handleChange(index, e.target.value)}
                                onFocus={(e) => e.target.select()}
                            />
                        ))}
                    </div>

                    <div className="switch-verification-method">
                        {!isEmailVerification ? (
                            <a className='switch-to-email' href="#" onClick={() => setShowEmailInput(true)}>לקבלת קוד באימייל</a>
                        ) : (
                            <a className='switch-to-sms' href="#" onClick={() => setShowSmsInput(true)}>לקבלת קוד ב-sms</a>
                        )}
                    </div>
                </>
            ) : showEmailInput ? (
                <>
                    <div className="Title-v-s-p">
                        <div className="SmsMessage-v-s-p">
                            <div className='txt-v-s-p'>כתובת המייל איתה נרשמת:</div>
                        </div>
                    </div>
                    <input
                        type="email"
                        placeholder="הכנס כתובת מייל"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="Input-Email-vsp"
                    />
                    <button className='send-email-btn' onClick={handleSendEmailCode}>שליחה</button>
                </>
            ) : (
                <>
                    <div className="Title-v-s-p">
                        <div className="SmsMessage-v-s-p">
                            <div className='txt-v-s-p'>לאיזה טלפון ישלח הקוד:</div>
                        </div>
                    </div>
                    <input
                        type="tel"
                        placeholder="הכנס מספר טלפון"
                        value={phoneInput}
                        onChange={(e) => setPhoneInput(e.target.value)}
                        className="Input-Email-vsp"
                    />
                    <button className='send-email-btn' onClick={handleSendSmsCode}>שליחה</button>
                </>
            )}
        </div>
    );
};

export default VerifySmsPopup;












// import React, { useEffect, useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { addTestToUser, getExamVersionsByPhone, loginUser, sendEmailVerificationCode, verifySmsCode } from '../../Services/UserService';
// import { useNavigate } from 'react-router-dom';
// import { fetchSwitchValue, updateScores } from '../../Services/TestService';
// import {useLocation } from 'react-router-dom';



// const VerifySmsPopup = ({ phone, email1, setShowPopup, onError, isEmailMode }) => {
//     const [code, setCode] = useState(['', '', '', '']); // array for the verification code
//     const [email, setEmail] = useState('');
//     const [showEmailInput, setShowEmailInput] = useState(false); // to display email input
//     const [showSmsInput, setShowSmsInput] = useState(false); // to display phone input
//     const [isEmailVerification, setIsEmailVerification] = useState(isEmailMode);
//     const [phoneInput, setPhoneInput] = useState(phone); // state for phone input
//     const navigate = useNavigate();
//     const dispatch = useDispatch();
//     const location =useLocation();
//     const Alltests = useSelector(state => state.Test.tests);


//     useEffect(() => {
//         dispatch(fetchSwitchValue('Switch')); // fetch the switch value
//     }, [dispatch]);

//     useEffect(()=>{
//         if(email){
//             setEmail(email1);
//         }
//     }, [])

//     const handleChange = (index, value) => {
//         if (/^\d$/.test(value) || value === '') { // check that the value is a digit or empty
//             const newCode = [...code];
//             newCode[index] = value; // set the digit at the correct index
//             setCode(newCode);

//             if (value !== '' && index < 3) { // if not empty and not at the end
//                 document.getElementById(`digit-${index + 1}`).focus(); // move focus to the next input
//             }

//             if (newCode.every(digit => digit !== '')) {
//                 handleVerifyCode(newCode.join('')); // verify once all digits are filled
//             }
//         }
//     };

//     useEffect(() => {
//         document.getElementById('digit-0').focus(); // start focus on the leftmost input
//     }, [dispatch]);

//     const isSwitchActive = useSelector(state => state.Test.isSwitchActive);

//     const handleFetchExamVersions = async () => {
//         const response = await getExamVersionsByPhone(phoneInput);
//         localStorage.setItem("proceduralVersion", response.proceduralVersion);
//         localStorage.setItem("substantiveVersion", response.substantiveVersion);
//     };

//     const resetVerificationState = () => {
//         // Reset the verification code input
//         setCode(['', '', '', '']);

//         // Show the previous input (email or phone) based on the verification method
//         if (isEmailVerification) {
//             setShowEmailInput(true);
//         } else {
//             setShowSmsInput(true);
//         }

//         // Close the current verification popup
//         setShowPopup(false);
//     };
//     const handleVerifyCode = async (verificationCode) => {
//         try {
//             const verificationResponse = isEmailVerification
//                 ? await verifySmsCode(email, verificationCode)
//                 : await verifySmsCode(phoneInput, verificationCode);

//             const user = verificationResponse.data.user;

//             if (user.permissionId === 1 || user.permissionId === 2) {
//                 navigate('/admin/poolOfTest');
//             } else {
//                 await handleAddTestToUser(user);
//             }
//             setShowPopup(false);
//             localStorage.setItem("token", verificationResponse.data.accessToken);
//             localStorage.setItem("user", JSON.stringify(user));
//         } catch (error) {
//             onError();
//             if (location.pathname === 'register') {
//                 navigate('/register');
//             } else if (location.pathname === 'login') {
//                 navigate('/login');
//             }
//         }
//     };

//     const handleCalculateScore = () => {
//         const user = JSON.parse(localStorage.getItem("user"));
//         const latestTestId = Alltests.length > 0 ? Alltests[Alltests.length - 1].Id : null;
//         updateScores({ UserId: user.Id, TestId: latestTestId });
//     };

//     const handleSendEmailCode = async () => {
//         try {
//             const response = await sendEmailVerificationCode(email);

//             if (response.status >= 200 && response.status < 300) {
//                 setIsEmailVerification(true); // switch to email verification
//                 setShowEmailInput(false); // hide email input
//             } else {
//                 alert("Failed to send verification code to email.");
//             }
//         } catch (error) {
//             console.error("Error sending email verification code:", error);
//         }
//     };

//     const handleSendSmsCode = async () => {
//         setPhoneInput(phoneInput); // Set phone input to the user-entered phone number
//         await handleSubmit(); // Directly call handleSubmit to trigger the SMS code sending and open the popup
//     };
//     const handleAddTestToUser = async (user) => {
//         const userId = user.id;
//         const versionOfDiscussion = parseInt(localStorage.getItem('versionOfDiscussion'), 10);
//         const versionOfSubstantive = parseInt(localStorage.getItem('versionOfSubstantive'), 10);
//         const discussionQuestions = JSON.parse(localStorage.getItem('discussionQuestions') || '[]').map(Number);
//         const substantiveQuestions = JSON.parse(localStorage.getItem('substantiveQuestions') || '[]').map(Number);
//         const response = await dispatch(addTestToUser(userId, versionOfDiscussion, versionOfSubstantive, discussionQuestions, substantiveQuestions));

//         if (window.location.pathname === '/login') {
//             if (isSwitchActive) {
//                 await handleFetchExamVersions();
//                 navigate('/user/test/mark');
//             } else {
//                 navigate('/user/test/finish', { state: { isSolutionReady: false } });
//             }
//         }

//         if (response.message === 'User successfully added to the test with answers.') {
//             if (window.location.pathname === '/register') {
//                 if (isSwitchActive) {
//                     await handleFetchExamVersions();
//                     await handleCalculateScore();
//                     navigate('/user/test/mark');
//                 } else {
//                     navigate('/user/test/finish', { state: { isSolutionReady: false } });
//                     console.log('Test successfully added');
//                 }
//             }
//         }
//     };

//     const handleSubmit = async () => {
//         const response = await loginUser(phone)

//         if (response?.message == "Verification code sent successfully.") {
//             setIsEmailVerification(false);
//             setShowSmsInput(false); // hide email input
//         }
//         else {
//             if ((response?.message == "You are not registered. Please register first.") ||
//                 (response?.message == "Your registration has expired. Please register again.")) {
//                 navigate('/register');
//             } else {
//                 alert('ההתחברות נכשלה');
//             }
//         }

//     };
//     return (
//         <div className="Sms-v-s-p" style={{ direction: 'rtl' }}>
//             <div className="Title-v-s-p">
//                 <div className="TitleText-v-s-p">התחברות</div>
//             </div>

//             {!showEmailInput && !showSmsInput ? (
//                 <>
//                     <div className="Title-v-s-p">
//                         <div className="SmsMessage-v-s-p">
//                             {!isEmailVerification ? (
//                                 <>
//                                     <div className='txt-v-s-p'>ברגע זה נשלחה אליך הודעת sms עם קוד אימות,</div>
//                                     <br />
//                                     <div className='txt-v-s-p'>נא להקליד את הקוד שהתקבל</div>
//                                 </>
//                             ) : (
//                                 <>
//                                     <div className='txt-v-s-p'>ברגע זה נשלחה אליך הודעת מייל עם קוד אימות,</div>
//                                     <br />
//                                     <div className='txt-v-s-p'>נא להקליד את הקוד שהתקבל</div>
//                                 </>
//                             )}
//                         </div>
//                     </div>

//                     <div className="VerificationCode-v-s-p">
//                         {code.map((digit, index) => (
//                             <input
//                                 key={index}
//                                 id={`digit-${index}`}
//                                 className="VerificationInput-v-s-p"
//                                 type="text"
//                                 maxLength="1"
//                                 value={digit}
//                                 onChange={(e) => handleChange(index, e.target.value)}
//                                 onFocus={(e) => e.target.select()}
//                             />
//                         ))}
//                     </div>

//                     <div className="switch-verification-method">
//                         {!isEmailVerification ? (
//                             <a className='switch-to-email' href="#" onClick={() => setShowEmailInput(true)}>לקבלת קוד באימייל</a>
//                         ) : (
//                             <a className='switch-to-sms' href="#" onClick={() => setShowSmsInput(true)}>לקבלת קוד ב-sms</a>
//                         )}
//                     </div>
//                 </>
//             ) : showEmailInput ? (
//                 <>
//                     <div className="Title-v-s-p">
//                         <div className="SmsMessage-v-s-p">
//                             <div className='txt-v-s-p'>כתובת המייל איתה נרשמת:</div>
//                         </div>
//                     </div>
//                     <input
//                         type="email"
//                         placeholder="הכנס כתובת מייל"
//                         value={email}
//                         onChange={(e) => setEmail(e.target.value)}
//                         className="Input-Email-vsp"
//                     />
//                     <button className='send-email-btn' onClick={handleSendEmailCode}>שליחה</button>
//                 </>
//             ) : (
//                 <>
//                     <div className="Title-v-s-p">
//                         <div className="SmsMessage-v-s-p">
//                             <div className='txt-v-s-p'>לאיזה טלפון ישלח הקוד:</div>
//                         </div>
//                     </div>
//                     <input
//                         type="tel"
//                         placeholder="הכנס מספר טלפון"
//                         value={phoneInput}
//                         onChange={(e) => setPhoneInput(e.target.value)}
//                         className="Input-Email-vsp"
//                     />
//                     <button className='send-email-btn' onClick={handleSendSmsCode}>שליחה</button>
//                 </>
//             )}
//         </div>
//     );
// };

// export default VerifySmsPopup;


// import React, { useEffect, useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { addTestToUser, getExamVersionsByPhone, loginUser, sendEmailVerificationCode, verifySmsCode } from '../../Services/UserService';
// import { useNavigate } from 'react-router-dom';
// import { fetchSwitchValue, updateScores } from '../../Services/TestService';
// import { useLocation } from 'react-router-dom';

// const VerifySmsPopup = ({ phone, email1, setShowPopup, onError, isEmailMode }) => {
//     const [code, setCode] = useState(['', '', '', '']); // array for the verification code
//     const [email, setEmail] = useState('');
//     const [showEmailInput, setShowEmailInput] = useState(false); // to display email input
//     const [showSmsInput, setShowSmsInput] = useState(false); // to display phone input
//     const [isEmailVerification, setIsEmailVerification] = useState(isEmailMode);
//     const [phoneInput, setPhoneInput] = useState(phone); // state for phone input
//     const [countdown, setCountdown] = useState(60); // countdown for resend code
//     const [isResendActive, setIsResendActive] = useState(false); // flag to check if resend is active
//     const navigate = useNavigate();
//     const dispatch = useDispatch();
//     const location = useLocation();
//     const Alltests = useSelector(state => state.Test.tests);

//     useEffect(() => {
//         dispatch(fetchSwitchValue('Switch')); // fetch the switch value
//     }, [dispatch]);

//     useEffect(() => {
//         if (email) {
//             setEmail(email1);
//         }
//     }, []);

//     // Handle code input change
//     const handleChange = (index, value) => {
//         if (/^\d$/.test(value) || value === '') { // check that the value is a digit or empty
//             const newCode = [...code];
//             newCode[index] = value; // set the digit at the correct index
//             setCode(newCode);

//             if (value !== '' && index < 3) { // if not empty and not at the end
//                 document.getElementById(`digit-${index + 1}`).focus(); // move focus to the next input
//             }

//             if (newCode.every(digit => digit !== '')) {
//                 handleVerifyCode(newCode.join('')); // verify once all digits are filled
//             }
//         }
//     };

//     useEffect(() => {
//         document.getElementById('digit-0').focus(); // start focus on the leftmost input
//     }, []);

//     const isSwitchActive = useSelector(state => state.Test.isSwitchActive);

//     const handleFetchExamVersions = async () => {
//         const response = await getExamVersionsByPhone(phoneInput);
//         localStorage.setItem("proceduralVersion", response.proceduralVersion);
//         localStorage.setItem("substantiveVersion", response.substantiveVersion);
//     };

//     const resetVerificationState = () => {
//         // Reset the verification code input
//         setCode(['', '', '', '']);

//         // Show the previous input (email or phone) based on the verification method
//         if (isEmailVerification) {
//             setShowEmailInput(true);
//         } else {
//             setShowSmsInput(true);
//         }

//         // Close the current verification popup
//         setShowPopup(false);
//     };

//     const handleVerifyCode = async (verificationCode) => {
//         try {
//             const verificationResponse = isEmailVerification
//                 ? await verifySmsCode(email, verificationCode)
//                 : await verifySmsCode(phoneInput, verificationCode);

//             const user = verificationResponse.data.user;

//             if (user.permissionId === 1 || user.permissionId === 2) {
//                 navigate('/admin/poolOfTest');
//             } else {
//                 await handleAddTestToUser(user);
//             }
//             setShowPopup(false);
//             localStorage.setItem("token", verificationResponse.data.accessToken);
//             localStorage.setItem("user", JSON.stringify(user));
//         } catch (error) {
//             onError();
//             if (location.pathname === 'register') {
//                 navigate('/register');
//             } else if (location.pathname === 'login') {
//                 navigate('/login');
//             }
//         }
//     };

//     const handleCalculateScore = () => {
//         const user = JSON.parse(localStorage.getItem("user"));
//         const latestTestId = Alltests.length > 0 ? Alltests[Alltests.length - 1].Id : null;
//         updateScores({ UserId: user.Id, TestId: latestTestId });
//     };

//     const handleSendEmailCode = async () => {
//         try {
//             const response = await sendEmailVerificationCode(email);

//             if (response.status >= 200 && response.status < 300) {
//                 setIsEmailVerification(true); // switch to email verification
//                 setShowEmailInput(false); // hide email input
//                 startResendCountdown(); // start countdown for resend button
//             } else {
//                 alert("Failed to send verification code to email.");
//             }
//         } catch (error) {
//             console.error("Error sending email verification code:", error);
//         }
//     };

//     const handleSendSmsCode = async () => {
//         setPhoneInput(phoneInput); // Set phone input to the user-entered phone number
//         await handleSubmit(); // Directly call handleSubmit to trigger the SMS code sending and open the popup

//     };

//     const [isResendInProgress, setIsResendInProgress] = useState(false);

//     const startResendCountdown = () => {
//         if (isResendInProgress) return;
        
//         setIsResendInProgress(true);
//         setCountdown(60);
        
//         const intervalId = setInterval(() => {
//             setCountdown(prev => {
//                 if (prev === 1) {
//                     clearInterval(intervalId);
//                     setIsResendInProgress(false);
                    
//                     // Sends the code once countdown reaches zero
//                     if (isEmailVerification) {
//                         handleSendEmailCode();
//                     } else {
//                         handleSendSmsCode();
//                     }
                    
//                     return 60;
//                 }
//                 return prev - 1;
//             });
//         }, 1000);
//     };

    
//     const handleResendCode = async () => {
//         if (!isResendActive) {
//             setIsResendActive(true); // Enable the countdown
//             setCode(['', '', '', '']); // Clear the code fields
//             startResendCountdown(); // Start 60-second countdown
//         }
//     };

//     const handleAddTestToUser = async (user) => {
//         const userId = user.id;
//         const versionOfDiscussion = parseInt(localStorage.getItem('versionOfDiscussion'), 10);
//         const versionOfSubstantive = parseInt(localStorage.getItem('versionOfSubstantive'), 10);
//         const discussionQuestions = JSON.parse(localStorage.getItem('discussionQuestions') || '[]').map(Number);
//         const substantiveQuestions = JSON.parse(localStorage.getItem('substantiveQuestions') || '[]').map(Number);
//         const response = await dispatch(addTestToUser(userId, versionOfDiscussion, versionOfSubstantive, discussionQuestions, substantiveQuestions));

//         if (window.location.pathname === '/login') {
//             if (isSwitchActive) {
//                 await handleFetchExamVersions();
//                 navigate('/user/test/mark');
//             } else {
//                 navigate('/user/test/finish', { state: { isSolutionReady: false } });
//             }
//         }

//         if (response.message === 'User successfully added to the test with answers.') {
//             if (window.location.pathname === '/register') {
//                 if (isSwitchActive) {
//                     await handleFetchExamVersions();
//                     await handleCalculateScore();
//                     navigate('/user/test/mark');
//                 } else {
//                     navigate('/user/test/finish', { state: { isSolutionReady: false } });
//                     console.log('Test successfully added');
//                 }
//             }
//         }
//     };

//     const handleSubmit = async () => {
//         const response = await loginUser(phone)

//         if (response?.message == "Verification code sent successfully.") {
//             setIsEmailVerification(false);
//             setShowSmsInput(false); // hide email input
//         }
//         else {
//             if ((response?.message == "You are not registered. Please register first.") ||
//                 (response?.message == "Your registration has expired. Please register again.")) {
//                 navigate('/register');
//             } else {
//                 alert('ההתחברות נכשלה');
//             }
//         }

//     };
//     return (
//         <div className="Sms-v-s-p" style={{ direction: 'rtl' }}>
//             <div className="Title-v-s-p">
//                 <div className="TitleText-v-s-p">התחברות</div>
//             </div>

//             {!showEmailInput && !showSmsInput ? (
//                 <>
//                     <div className="Title-v-s-p">
//                         <div className="SmsMessage-v-s-p">
//                             {!isEmailVerification ? (
//                                 <>
//                                     <div className='txt-v-s-p'>ברגע זה נשלחה אליך הודעת sms עם קוד אימות,</div>
//                                     <br />
//                                     <div className='txt-v-s-p'>נא להקליד את הקוד שהתקבל</div>
//                                 </>
//                             ) : (
//                                 <>
//                                     <div className='txt-v-s-p'>ברגע זה נשלחה אליך הודעת מייל עם קוד אימות,</div>
//                                     <br />
//                                     <div className='txt-v-s-p'>נא להקליד את הקוד שהתקבל</div>
//                                 </>
//                             )}
//                         </div>
//                     </div>

//                     <div className="VerificationCode-v-s-p">
//                         {code.map((digit, index) => (
//                             <input
//                                 key={index}
//                                 id={`digit-${index}`}
//                                 className="VerificationInput-v-s-p"
//                                 type="text"
//                                 maxLength="1"
//                                 value={digit}
//                                 onChange={(e) => handleChange(index, e.target.value)}
//                                 onFocus={(e) => e.target.select()}
//                             />
//                         ))}
//                     </div>

//                     <div className="switch-verification-method">
//                         {!isEmailVerification ? (
//                             <a className='switch-to-email' href="#" onClick={() => setShowEmailInput(true)}>לקבלת קוד באימייל</a>
//                         ) : (
//                             <a className='switch-to-sms' href="#" onClick={() => setShowSmsInput(true)}>לקבלת קוד ב-sms</a>
//                         )}
//                     </div>

             
//                         <button className='send_taimer'
//                             onClick={handleResendCode}
//                         // disabled={isResendActive}
//                         >
//                             {isResendActive ? `שלח שוב (${countdown})` : 'שלח שוב'}
//                         </button>

//                 </>
//             ) : showEmailInput ? (
//                 <>
//                     <div className="Title-v-s-p">
//                         <div className="SmsMessage-v-s-p">
//                             <div className='txt-v-s-p'>כתובת המייל איתה נרשמת:</div>
//                         </div>
//                     </div>
//                     <input
//                         type="email"
//                         value={email}
//                         onChange={(e) => setEmail(e.target.value)}
//                         className="Email-input-v-s-p"
//                     />
//                     <div className="resend-code">
//                         <button onClick={handleSendEmailCode} disabled={isResendActive}>שלח קוד אימות</button>
//                     </div>
//                 </>
//             ) : (
//                 <>
//                     <div className="Title-v-s-p">
//                         <div className="SmsMessage-v-s-p">
//                             <div className='txt-v-s-p'>טלפון נייד שלך:</div>
//                         </div>
//                     </div>
//                     <input
//                         type="tel"
//                         value={phoneInput}
//                         onChange={(e) => setPhoneInput(e.target.value)}
//                         className="PhoneInput-v-s-p"
//                     />
//                     <div className="resend-code">
//                         <button onClick={handleSendSmsCode} disabled={isResendActive}>שלח קוד אימות</button>
//                     </div>
//                 </>
//             )}
//         </div>
//     );
// };

// export default VerifySmsPopup;







import React, { useState } from 'react';
import { IconButton, Menu, MenuItem, Typography } from '@mui/material';
import { MoreVert, Phone } from '@mui/icons-material';
import { ReactComponent as IconUser } from '../../../assets/user.svg';
import DeleteAdmin from './DeleteAdmin';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { ReactComponent as UpdateIcon } from '../../../assets/update_svg.svg';

const AdminItem = ({ admin, onEdit, onDelete }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [isHovered, setIsHovered] = useState(false);
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleDeleteOpen = () => {
        setIsDeleteOpen(true);
        handleMenuClose(); // Close the menu when opening the delete confirmation popup
    };

    const handleDeleteClose = () => {
        setIsDeleteOpen(false);
    };

    return (
        <div
            className={`admin-card ${isHovered ? 'hovered' : ''}`}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <div className="content">
                <div className="avatar-section">
                    <div className="avatar">
                        <IconUser className="user-icon" style={{ borderColor: '#0C82AB' }} />
                    </div>
                    <div className='admin-name'>{admin.name}</div>
                </div>

                {isHovered && (
                    <IconButton onClick={handleMenuOpen} className='three-dots-btn'>
                        <MoreVert />
                    </IconButton>
                )}

                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                    disablePortal
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <MenuItem onClick={() => { handleMenuClose(); onEdit(); }}>
                        <UpdateIcon />
                    </MenuItem>
                    <MenuItem onClick={handleDeleteOpen}>
                        <DeleteAdmin
                            adminId={admin.id}  // העברת מזהה האדמין למחיקה
                            open={isDeleteOpen}
                            onClose={handleDeleteClose}
                            onDelete={() => {
                                onDelete();
                                handleDeleteClose();
                            }}
                        />
                    </MenuItem>
                </Menu>

                <div className="data-section">
                    <div className="email-section">
                        <div className='admin-email'>{admin.email}</div>
                        <IconButton sx={{ width: 24, height: 24 }}>
                            <EmailOutlinedIcon sx={{ color: '#17C2D0' }} />
                        </IconButton>
                    </div>
                    <div className="phone-section">
                        <div className='admin-phone'>{admin.phone}</div>
                        <IconButton sx={{ width: 24, height: 24 }}>
                            <Phone sx={{ color: '#17C2D0' }} />
                        </IconButton>
                    </div>
                </div>
            </div>

            <div className={`role-badge ${admin.permissionId === 1 ? 'super-admin' : 'admin'}`}>
                <Typography variant="body1">
                    {admin.permissionId === 1 ? 'Super Admin' : 'Admin'}
                </Typography>
            </div>
        </div>
    );
};

export default AdminItem;

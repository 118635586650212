


// ../action.js
export const REGISTER = "REGISTER";
export const LOG_IN = "LOG_IN";
export const LOG_OUT = "LOG_OUT";

export const GET_TESTS = "GET_TESTS";
export const ADD_TEST = "ADD_TEST";
export const EDIT_TEST = "EDIT_TEST";
export const DELETE_TEST = "DELETE_TEST";
export const GET_AVERAGE_SCORES = "GET_AVERAGE_SCORES";
export const GET_TEST_RESULTS = "GET_TEST_RESULTS";
export const GET_EXAMANSWERS = "GET_EXAMANSWERS";
export const SET_SELECTED_VERSION = "SET_SELECTED_VERSION";
export const GET_SELECTED_VERSION = "GET_SELECTED_VERSION";
export const SET_SWITCH_VALUE="SET_SWITCH_VALUE";
export const SET_IS_ACTIVE_APP="SET_IS_ACTIVE_APP";


import * as actionType from '../action';

const initialState = {
    tests: [],
    examAnswers: [],
    averageScores: {},
    testResults: {},
    versionSelected: 1,
    isSwitchActive: false,
    isActiveApp:false,
};

const TestReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.GET_TESTS: {
            return { ...state, tests: action.payload };
        }
        case actionType.ADD_TEST: {
            const tests = [...state.tests];
            tests.push(action.payload);
            return { ...state, tests };
        }
        case actionType.EDIT_TEST: {
            const tests = [...state.tests];
            const findIndex = tests.findIndex(x => x.id === action.payload.id);
            console.log('before', tests[findIndex]);
            tests[findIndex] = action.payload;
            console.log('after', tests[findIndex]);
            return { ...state, tests };
        }
        case actionType.DELETE_TEST: {
            const tests = state.tests.filter(x => x.id !== action.payload);
            return { ...state, tests };
        }
        case actionType.GET_AVERAGE_SCORES:
            return {
                ...state,
                averageScores: {
                    ...state.averageScores,
                    [action.payload.testId]: action.payload
                }
            };
        case actionType.GET_TEST_RESULTS:
            return {
                ...state,
                testResults: {
                    ...state.testResults,
                    [action.payload.testId]: action.payload.countOfExaminees
                }
            };

        case actionType.SET_SELECTED_VERSION:
            return{
                ...state,
                versionSelected:action.payload
            }
        case actionType.GET_EXAMANSWERS: {
            return { ...state, examAnswers: action.payload }
        };
        case actionType.SET_SWITCH_VALUE:
            return {
                ...state,
                isSwitchActive: action.payload,
            };
        case actionType.SET_IS_ACTIVE_APP:
            return{
                ...state,
                isActiveApp:action.payload,
            };
        default:
            return { ...state };
    }
}

export default TestReducer;

import React, { useEffect, useState } from 'react';
import '../style/LandingPage.css';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchIsActiveApp, fetchTests } from '../Services/TestService';



const LandingPage = () => {
  const logoImage = require('../../src/assets/easylaw-logo-01.jpg');
  const logoImage2 = require('../../src/assets/easylaw-logo-01 copy.png');
  const arrow_left = require('../../src/assets/Alt Arrow Left.png');
  const[title,setTitle]=useState('');
  const [animate, setAnimate] = useState(false);

  const navigate = useNavigate();
  const dispatch=useDispatch();
  const isActiveApp = useSelector(state => state.Test.isActiveApp);
  const Alltests = useSelector(state => state.Test.tests);
console.log("Alltests",Alltests);

useEffect(() => {
  // להתחיל את האנימציה שניה לאחר טעינת הדף
  const timer = setTimeout(() => setAnimate(true), 1000);
  return () => clearTimeout(timer);
}, []);

useEffect(() => {
  dispatch(fetchIsActiveApp('IsActiveApplication'));
}, [dispatch]);

useEffect(() => {
  dispatch(fetchTests());
}, [dispatch]);

  const handleEnterTest=()=>{
    localStorage.removeItem('discussionQuestions');
    localStorage.removeItem('VersionOfDiscussion');
    localStorage.removeItem('SubstantiveQuestions');
    localStorage.removeItem('VersionOfSubstantive');
    navigate('/user/test');
  }




  // const getLastTestTitle =async () => {
  //   // ממיינת את המבחנים לפי התאריך (מהקרוב ביותר לרחוק ביותר)
  //   const sortedTests =await Alltests.sort((a, b) => new Date(b.dateOfTest) - new Date(a.dateOfTest));
  //   // שולפת את הכותרת של המבחן האחרון
  //   console.log("Alltests",Alltests)
  //   const lastTestTitle = sortedTests[0];
  //   console.log("lastTestTitle",lastTestTitle);
  
  //   return lastTestTitle;

  // };

  const getLastTestTitle = async () => {
    // ממיין את המבחנים לפי התאריך (מהקרוב ביותר לרחוק ביותר)
    const sortedTests = Alltests.sort((a, b) => new Date(b.dateOfTest) - new Date(a.dateOfTest));
  
    // מקבל את המבחן הראשון לאחר המיון
    const lastTest = sortedTests[0];
  
    if (lastTest) {
      // מחזיר את titleName של המבחן הראשון
      return lastTest.titleName;
    } else {
      console.log("No tests available");
      return null;
    }
  };
  const fetchTitle = async () => {
    const titleOfTest = await getLastTestTitle();
    setTitle(titleOfTest);
    console.log("Title of the last test:", titleOfTest);
  };
  
  fetchTitle();

  if (!isActiveApp) {
    return (
      <div className="inactive-message-container">
             <div className="logo-sign">
        <img className="logo-img-sign" src={logoImage2} alt="Logo" />
      </div>
        <div className="inactive-message">
          <p>המערכת פעילה בסמוך למועדי בחינות הלשכה.</p>
          <p>
          לרישום לקורס ההכנה של איזילו לבחינות הלשכה יש ללחוץ
          <a
            href="https://hi.easylaw.io/bar"
            target="_blank"
            rel="noopener noreferrer"
            className={`animated-link ${animate ? 'animate' : ''}`}
          > כאן</a>.
        </p>
        </div>
      </div>
    );
    
  }
  return (
    <div className="figma-container-landing">
      <div className="frame1707478343-landing">
        <div className="frame21-landing">
          <img className="easylaw-logo-011-landing" src={logoImage} alt="Easylaw Logo" />
        </div>
        <div className="frame1707478210-landing">
          <div className="frame14-landing">
            <div className="title-landing">ציון משוער</div>
            <div className="frame16-landing">
              <div className="title-landing">
                <div className="exam-date-landing">{title}</div>
              </div>
            </div>
          </div>
          <div className="frame22-landing">
            <div className="frame16-landing">
              <div className="title-landing">
                <div className="easylaw41-landing">
                  <span>
                   מזינים תשובות ומקבלים ציון משוער
                    <br />
                  </span>
                  <span className='text-easy'>
                  הציון המשוער מבוסס על פתרון מטעם הצוות המקצועי של חברת EASYLAW
                    <br />
                   תיתכן סטייה של 4-1 נקודות בין הציון המשוער לציון לאחר הפתרון הרשמי.
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="frame1707478411-landing">
            <button className="button-landing-1" onClick={() => handleEnterTest()}>
              <img src={arrow_left} alt="חץ שמאלה" className="arrow-left" />
              המשך להזנת התשובות
            </button>
            <div className="button-landing-2">
              <div className="button-master-landing">
                <div className="button-login-landing">
                  <div>
                    <span>כבר הזנת?</span>
                    <span className="login-link" onClick={() => { navigate('/login') }}>התחברות</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;


import TestReducer from "./reducer/TestReducer";
import UserReducer from "./reducer/UserReducer";
import { createStore, combineReducers, applyMiddleware } from 'redux';
import { thunk } from 'redux-thunk'


const reducers=combineReducers({
    User:UserReducer,
    Test:TestReducer,
})

export const store=createStore(reducers,applyMiddleware(thunk))
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getCorrectAnswers, getExamineesByTestId, getQuestionIndexesByVersion, getQuestionStatistics, updateCorrectAnswers, updateScores } from '../../Services/TestService';
import { ReactComponent as IconOfPlus } from '../../assets/plus.svg';
import { ReactComponent as IconOfSheet } from '../../assets/sheetIcon.svg';
import '../../style/StatisticTable.css';
import CommentsOfQuestion from './commentsOfQuestion';
import { getCommentsByQuestionId } from '../../Services/TestService';

const arr = ['', 'א', 'ב', 'ג', 'ד'];

const StatisticsTable = () => {
    const [updatedAnswers, setUpdatedAnswers] = useState([]);
    const [currentLawType, setCurrentLawType] = useState('דין דיוני');
    const [hoveredNoteIndex, setHoveredNoteIndex] = useState(null);
    const [questions, setQuestions] = useState([]);
    const [statistics, setStatistics] = useState([]);
    const [display, setDisplay] = useState(false);
    const testDisplay = localStorage.getItem('test');
    const testDetails = JSON.parse(testDisplay);
    const [commentCounts, setCommentCounts] = useState({});

    const version = useSelector(state => state.Test.versionSelected);
    useEffect(() => {
        fetchQuestions(currentLawType);
    }, [version, testDetails.testId, currentLawType]);

    const userString = localStorage.getItem('user');
    let userObject = null;

    if (userString) {
        userObject = JSON.parse(userString);
    }

    useEffect(() => {
        fetchStatistics();
        fetchAllCommentCounts();
    }, [currentLawType, testDetails.testId, questions]);

    const fetchQuestions = async (lawType) => {
        try {
            const typeOfExamId = lawType === 'דין דיוני' ? 1 : 2;
            await getQuestionIndexesByVersion(testDetails.testId, typeOfExamId).then(res => {
                setQuestions(res);
            });
        } catch (error) {
            console.error('Failed to fetch questions:', error);
        }
    };

    const fetchStatistics = async () => {
        try {
            const typeOfExamId = currentLawType === 'דין דיוני' ? 1 : 2;
            const data = await getQuestionStatistics(testDetails.testId, typeOfExamId);
            setStatistics(data);
        } catch (error) {
            console.error('Failed to fetch question statistics:', error);
        }
    };

    const handleInputChange = (index, event) => {
        const newAnswers = [...updatedAnswers];
        let inputValue = event.target.value;

        if (arr.includes(inputValue)) {
            inputValue = arr.indexOf(inputValue);
        } else if (parseInt(inputValue) && inputValue <= 4 && inputValue > 0) {
            inputValue = parseInt(inputValue);
        } else if (inputValue === '') {
            inputValue = null; // Set to null to indicate no answer
        } else {
            inputValue = null; // Set to null to indicate no answer
        }

        newAnswers[index] = {
            ...newAnswers[index],
            selectedAnswer: inputValue
        };
        setUpdatedAnswers(newAnswers);
    };

    const convertValue = (value) => {
        if (value === null || value === undefined) {
            return ''; // במקרה של ערך ריק, החזר מחרוזת ריקה
        }
        return arr[value];
    };

    const handleSaveAnswers = async () => {
        try {
            const existingAnswers = await getCorrectAnswers(testDetails.testId, currentLawType === 'דין דיוני' ? 1 : 2);
            const updatedAnswersList = questions.map((question, index) => {
                const newAnswer = updatedAnswers[index]?.selectedAnswer;
                const existingAnswer = existingAnswers.find(answer => answer.Version1Index === question.version1Index)?.SelectedAnswer;

                if (newAnswer !== existingAnswer) {
                    return {
                        Version1Index: question.version1Index,
                        SelectedAnswer: newAnswer
                    };
                }

                return null;
            }).filter(answer => answer !== null);

            const payload = {
                TestId: testDetails.testId,
                TypeOfExamId: currentLawType === 'דין דיוני' ? 1 : 2,
                Questions: updatedAnswersList
            };

            await updateCorrectAnswers(payload);
            fetchQuestions(currentLawType);
            alert('Answers updated successfully');
            setUpdatedAnswers([]);
            const examinees = await getExamineesByTestId(testDetails.testId);
            for (const examinee of examinees) {
                await updateScores({ UserId: examinee.userId, TestId: testDetails.testId });
            }

            alert('Scores updated successfully for all examinees.');
        } catch (error) {
            console.error('Failed to update answers:', error);
            alert('Error updating answers');
        }
    };

    const handleOpenComments = (index) => {
        setHoveredNoteIndex(index);
        setDisplay(true);
    };

    const handleCommentCountUpdate = (index, count) => {
        setCommentCounts(prevCounts => ({
            ...prevCounts,
            [index]: count
        }));
    };

    const renderStatisticBar = (percentage) => {
        const isAbove60 = percentage >= 60;
        return (
            <div
                className={`percentage-bar ${isAbove60 ? 'green-bar' : 'yellow-bar'}`}
                style={{ width: `${percentage}%` }}
            >
                {percentage}%
            </div>
        );
    };

    useEffect(() => {
        fetchAllCommentCounts();
    }, [questions]);

    const fetchAllCommentCounts = async () => {
        const counts = {};
        try {
            for (const question of questions) {
                const response = await getCommentsByQuestionId(testDetails.testId, currentLawType === 'דין דיוני' ? 1 : 2, question.version1Index, userObject.id, userObject.permissionId);
                counts[question.version1Index] = response.length;
            }
            await setCommentCounts(counts);
        } catch (error) {
            console.error('Failed to fetch comment counts:', error);
        }
    };

    return (
        <div className='all-statistic'>
            {hoveredNoteIndex !== null && display && (
                <div className="comments-container-t-s">
                    <CommentsOfQuestion testId={testDetails.testId} typeOfExamId={currentLawType === "דין דיוני" ? 1 : 2} version1Index={hoveredNoteIndex} setDisplay={setDisplay} setCommentCount={(count) => handleCommentCountUpdate(hoveredNoteIndex, count)} />
                </div>
            )}
            <div className="header-container-t-s">
                <div className="header-title-t-s" style={{ height: '7vh' }}>
                    <h2>פתרון וסטטיסטיקה</h2>
                </div>
            </div>
            <div className="actions-container-t-s">
                <button className="update-button-t-s" onClick={handleSaveAnswers}>עדכון תשובות</button>
                <div className="subtitle-frame-t-s">
                    <div className="subtitle-container-t-s">
                        <div
                            className={`subtitle ${currentLawType === 'דין מהותי' ? 'subtitle-active' : 'subtitle-inactive'}`}
                            onClick={() => setCurrentLawType('דין מהותי')}
                        >
                            דין מהותי
                        </div>
                        <div
                            className={`subtitle ${currentLawType === 'דין דיוני' ? 'subtitle-active' : 'subtitle-inactive'}`}
                            onClick={() => setCurrentLawType('דין דיוני')}
                        >
                            דין דיוני
                        </div>
                    </div>
                </div>
            </div>
            <div className="table-container-t-s">
                <TableContainer className="Frame17074782891-t-s" component={Paper} style={{ borderRadius: '16px' }}>
                    <Table>
                    <TableHead className="TableHead-t-s sticky-header" style={{ fontFamily: "Rubik" }}>
                            <TableRow>
                                <TableCell align="center" >הערות</TableCell>
                                {(userObject) && (userObject.permission) && (userObject.permission.name === "SuperAdmin") && (
                                    <>
                                        <TableCell align="center">תשובה נכונה</TableCell>
                                        <TableCell align="center">תוצאות</TableCell>
                                    </>
                                )}
                                <TableCell align="center">מס' שאלה לפי גירסה 1</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {questions.map((question, index) => {
                                const questionStatistics = statistics.find(stat => stat.questionIndex === question.version1Index);

                                return (
                                    <TableRow key={index}>
                                        <TableCell align="center" className='Frame1707478263-t-s'>
                                            <div className="Container-t-s">
                                                <div className="AvatarAddButton-t-s">
                                                    <IconOfPlus className="Icon-t-s" />
                                                </div>
                                                <button
                                                    className="Note-t-s"
                                                    onClick={() => handleOpenComments(question.version1Index - 1)}
                                                >
                                                    <IconOfSheet />
                                                    <div className="Text2-t-s">
                                                        {commentCounts[question.version1Index - 1] || 0}
                                                    </div>
                                                </button>
                                            </div>
                                        </TableCell>
                                        {(userObject) && (userObject.permission) && (userObject.permission.name === "SuperAdmin") && (
                                            <>
                                                <TableCell align="center">
                                                    <input
                                                        type="text"
                                                        value={convertValue(updatedAnswers[index]?.selectedAnswer) || convertValue(question.selectedAnswer) || ''}
                                                        onChange={(event) => handleInputChange(index, event)}
                                                        className="Frame1707478224-t-s"
                                                        placeholder="הכנס תשובה"
                                                    />
                                                </TableCell>
                                                <TableCell align="center">
                                                    {questionStatistics ? (
                                                        <div>
                                                            <div className='countValue styledRow' style={{ display: 'flex', direction: 'rtl', justifyContent: 'center' }}>
                                                                <span className="optionLetter">א</span> {questionStatistics.countValue1}  {renderStatisticBar(questionStatistics.percentageAnswer1)}
                                                            </div>
                                                            <div className='countValue styledRow' style={{ display: 'flex', direction: 'rtl', justifyContent: 'center' }}>
                                                                <span className="optionLetter">ב</span> {questionStatistics.countValue2}  {renderStatisticBar(questionStatistics.percentageAnswer2)}
                                                            </div>
                                                            <div className='countValue styledRow' style={{ display: 'flex', direction: 'rtl', justifyContent: 'center' }}>
                                                                <span className="optionLetter">ג</span> {questionStatistics.countValue3}  {renderStatisticBar(questionStatistics.percentageAnswer3)}
                                                            </div>
                                                            <div className='countValue styledRow' style={{ display: 'flex', direction: 'rtl', justifyContent: 'center' }}>
                                                                <span className="optionLetter">ד</span> {questionStatistics.countValue4}  {renderStatisticBar(questionStatistics.percentageAnswer4)}
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div>אין נתונים זמינים</div>
                                                    )}
                                                </TableCell>

                                            </>
                                        )}
                                        <TableCell align="center">{question.version1Index}</TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    );
};

export default StatisticsTable;


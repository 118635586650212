import '../../../style/PopupAddNewTest.css'
import React, { useState } from 'react';
import { ReactComponent as IconAddTest } from '../../../assets/new test.svg';
import { useDispatch } from 'react-redux';
import { addTest } from '../../../Services/TestService';



const PopupAddNewTest = ({ onClose }) => {
  const [title, setTitle] = useState('');

  const dispatch = useDispatch();

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleSubmit = () => {
    const testData = {
      TitleName: title,
    };

    dispatch(addTest(testData));
    onClose(); // אם רוצים לסגור את הפופאפ לאחר הוספה
  };
  return (
    <div className="CommentWriting-p-a-n-t">
      <div className="Frame1707478212-p-a-n-t">
        <div className="Frame1707478323-p-a-n-t">
          <div className="Frame1707478398-p-a-n-t">
            <div className="popup-title-p-a-n-t">הוספת מבחן חדש</div>
            <IconAddTest className="IconNewTest-p-a-n-t"/>
            
          </div>
        </div>
      </div>
      <div className="Frame1707478217-p-a-n-t">
        <div className="TextFieldCaption-p-a-n-t">
          <div className="Frame21684-p-a-n-t">
            <div className="label-p-a-n-t">כותרת המבחן</div>
          </div>
          <div className="TextField-p-a-n-t">
            <input 
              className="input-placeholder-p-a-n-t" 
              type="text" 
              value={title} 
              onChange={handleTitleChange} 
              placeholder="איזה שם יופיע למבחן" 
            />
          </div>
        </div>
        <div className="Frame22-p-a-n-t">
        <button
            className={`ButtonConfirm-p-a-n-t ${title ? 'ButtonConfirmActive' : ''}`}
            onClick={handleSubmit}
            disabled={!title} // הכפתור יושבת כאשר title ריק
          >
            אישור
          </button>
          <button className="ButtonCancel-p-a-n-t" onClick={onClose}>
            ביטול
          </button>
        </div>
      </div>
    </div>
  );
}

export default PopupAddNewTest;

import React from 'react';
import '../../../style/PopupDeleteTest.css'; // Import the CSS file for styling
import {  Dialog} from '@mui/material';


const PopupDeleteTest = ({ onClose, onConfirm }) => {


    return (
        <Dialog open={onConfirm} onClose={onClose} style={{ backgroundColor: "rgba(255, 255, 255, 0.1)"}}>
        <div className="PopupError-p-d-t" >
            <div className="Frame1707478455-p-d-t">
                <div className="Frame14-p-d-t">
                    <div className="Title2-p-d-t">אישור מחיקת מבחן</div>
                </div>
                <div className="Title-p-d-t">
                    <div className="Message-p-d-t">
                        במחיקת המבחן ימחקו כל הנתונים ולא ניתן יהיה אחר כך לשחזרם
                    </div>
                    <div className="ConfirmationQuestion-p-d-t">
                        האם הינך רוצה למחוק בכל זאת?
                    </div>
                </div>
            </div>
            <div className="Frame22-p-d-t">
                <div className="Button-p-d-t" onClick={onConfirm}>
                    <div className="ButtonMaster-p-d-t">
                        <div className="ButtonConfirm-p-d-t">
                            <div className="ButtonText-p-d-t">אישור</div>
                        </div>
                    </div>
                </div>
                <div className="Button-p-d-t" onClick={onClose}>
                    <div className="ButtonMaster-p-d-t">
                        <div className="ButtonCancel-p-d-t">
                            <div className="ButtonText-p-d-t">ביטול</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </Dialog>
    );
};

export default PopupDeleteTest;



import '../../style/CommentsOfQuestion.css';
import { ReactComponent as IconOfPlus } from '../../assets/plus-white.svg';
import { ReactComponent as IconUser } from '../../assets/usersagol.svg';
import { ReactComponent as IconAlertCircle } from '../../assets/alert-circle-.svg';
import { addCommentToQuestion, getCommentsByQuestionId } from '../../Services/TestService';
import { getUserById } from '../../Services/UserService';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import React, { useState, useEffect, useRef } from 'react';

const CommentsOfQuestion = ({ testId, typeOfExamId, version1Index, setDisplay, setCommentCount }) => {
  const [showPopupAddComment, setShowPopupAddComment] = useState(false);
  const [comments, setComments] = useState([]);
  const [userNames, setUserNames] = useState({});
  const userString = localStorage.getItem('user');
  let userObject = null;

  if (userString) {
    userObject = JSON.parse(userString);
  }

  const handleOpenPopup = () => {
    setShowPopupAddComment(true);
  };

  const handleClose = () => {
    setDisplay(false); // קריאה לפונקציה שסוגרת את התצוגה
  };

  useEffect(() => {
    fetchComments();
  }, [testId, typeOfExamId, version1Index]);

  const fetchComments = async () => {
    try {
      const response = await getCommentsByQuestionId(testId, typeOfExamId, version1Index, userObject.id, userObject.permissionId);  // הוספת userId ו-permissionId
      setComments(response);
      setCommentCount(response.length);

      // Fetch usernames for each comment
      const names = {};
      for (const comment of response) {
        const user = await getUserById(comment.userId);
        names[comment.userId] = user.name;
      }
      setUserNames(names);
    } catch (error) {
      console.error('Failed to fetch comments:', error);
    }
  };

  const filterComments = (comment) => {
    if (userObject?.permissionId === 1) {
      // סופר אדמין רואה את כל ההערות
      return true;
    } else if (userObject?.permissionId === 2) {
      // אדמין רואה רק את ההערות שהוא יצר
      return comment.userId === userObject?.id;
    }
    return false; // משתמשים אחרים לא רואים הערות
  };
  return (
    <div className="notes-container">
      <IconButton className="close-button" onClick={handleClose}>
        <CloseIcon />
      </IconButton>
      <div className="notes-content">
        {comments.map((note, index) => (
          <div className="note-content" key={index}>
            <div className="note-header">
              <div className="author">נוצר ע"י {userNames[note.userId]}</div>
              <div className="avatar-question">
                <IconUser className="user-icon" />
                <div className="avatar-online-indicator"></div>
              </div>
            </div>
            <div className="note-body">
              {note.textComment}
            </div>
          </div>
        ))}
      </div>
      <button className='add-Comment' onClick={handleOpenPopup}>
        <IconOfPlus />
      </button>
      {showPopupAddComment && (
        <div className='openPopupAddComm'>
          <PopupAddCommentToQuestion
            onClose={() => setShowPopupAddComment(false)}
            testId={testId}
            typeOfExamId={typeOfExamId}
            version1Index={version1Index}
            userId={userObject}
            getComments={fetchComments}
          />
        </div>
      )}
    </div>
  );
};

export default CommentsOfQuestion;







// import { IconAlertCircle } from '@tabler/icons-react';
const PopupAddCommentToQuestion = ({ onClose, testId, typeOfExamId, version1Index, userId, getComments }) => {
  const [messageBody, setMessageBody] = useState('');
  const linesPlus = require('../../assets/lines-plus.jpg');
  const popupRef = useRef(null);
  const maxLength = 1000;

  const handleBodyChange = (e) => {
    setMessageBody(e.target.value);
    adjustTextareaHeight(e.target);
  };

  const adjustTextareaHeight = (textarea) => {
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;
  };

  const handleSubmit = async () => {
    try {
      console.log(testId, typeOfExamId, version1Index, userId, messageBody);
      await addCommentToQuestion(testId, typeOfExamId, version1Index, userId.id, messageBody).then(() => {
        onClose();
        getComments();
      });
    } catch (error) {
      console.error('Failed to add comment:', error);
    }
  };

  return (
    <div className="frame1707478337-p-a-c">
      <div className="frame1707478212-p-a-c">
        <div className="frame1707478323-p-a-c">
          <div className="frame1707478398-p-a-c">
            <div className="header-text-p-a-c">הוספת הערה</div>
            <img src={linesPlus} className="icon-container-p-a-c" alt="icon" />
          </div>
          <div className="subheader-text-p-a-c">
            שאלה מס’ {version1Index + 1} - {typeOfExamId == 1 ? 'דין דיוני' : 'דין מהותי'}
          </div>
        </div>
      </div>
      <div className="frame1707478217-p-a-c">
        <div className="frame1707478397-p-a-c">
          <div className="text-label-p-a-c">תוכן הערה</div>
          <div className="frame1707478396-p-a-c">
            <textarea
              className="textfield-base-p-a-c"
              placeholder="מה תרצה להוסיף"
              maxLength={maxLength}
              value={messageBody}
              onChange={handleBodyChange}
              rows={1}
              style={{ overflow: 'hidden', resize: 'none' }}
            />
            <div className="char-limit-p-a-c">
              {messageBody.length === maxLength ? (
                <div className="warning-text-p-a-c">
                  מוגבל ל-{maxLength} תווים
                  <IconAlertCircle alt="warning" className="warning-icon-p-a-c" />
                </div>
              ) : (
                `${messageBody.length}/${maxLength} תווים`
              )}
            </div>
          </div>
        </div>
        <div className="buttons-p-a-c">
          <button
            className={`button-p-a-c confirm-button-p-a-c ${messageBody ? 'active' : ''}`}
            onClick={handleSubmit}
            disabled={!messageBody}  // כפתור יהיה מושבת אם אין תוכן
          >
            אישור
          </button>
          <button className="button-p-a-c cancel-button-p-a-c" onClick={onClose}>
            ביטול
          </button>
        </div>
      </div>
    </div>
  );
};

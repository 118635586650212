import React, { useEffect, useState } from 'react';
import '../../../style/SideBar.css';
import { getSuperAdminsOrAdmins } from '../../../Services/UserService';
import '../../../style/displayAllAdmin.css';
import AddOrEditAdmin from './addOrEditAdmin';
import AdminItem from './adminItem';
import { ReactComponent as IconOfPlus } from '../../../assets/plus-white.svg';
import { useNavigate } from 'react-router-dom';


const AllAdminCards = () => {
    const return_icon = require('../../../assets/return.png');
    const [permissionId, setPermissionId] = useState(null);
    const [admins, setAdmins] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [editingAdmin, setEditingAdmin] = useState(false);
    const [selectedAdmin, setSelectedAdmin] = useState(null);
    
    const navigate=useNavigate();
    useEffect(() => {
        const fetchUser = async () => {
            const userFromStorage = localStorage.getItem("user");
            if (userFromStorage) {
                const parsedUser = JSON.parse(userFromStorage);
                await setPermissionId(parsedUser.permissionId);
            }
        };
        fetchUser();
    }, []);

    useEffect(() => {
        if (permissionId !== null) {
            const fetchAdmins = async () => {
                try {
                    const data = await getSuperAdminsOrAdmins(permissionId);
                    await setAdmins(data);
                } catch (error) {
                    console.error("Error fetching admins:", error);
                }
            };

            fetchAdmins();
        }
    }, [permissionId]);

    const handleOpenDialog = (admin) => {
        setSelectedAdmin(admin);
        setEditingAdmin(!!admin); // אם יש admin אז זה עריכה
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setSelectedAdmin(null);
    };

    const handleAdminUpdated = async () => {
        const updatedAdmins = await getSuperAdminsOrAdmins(permissionId);
        setAdmins(updatedAdmins);
    };

    return (
        <>
                <div className="Frame1707478457-s-b">
                    <div
                        className={`Frame1707478340_inner-s-b`}
                        onClick={() => { navigate('/admin/poolOfTest') }}
                    >
                        <div className="Text-s-b">מאגר מבחנים</div>
                        <img src={return_icon} className="IconAltArrowRight-s-b" />
                    </div>

              
                </div>
            <button className='btn-add-admin' onClick={() => handleOpenDialog()}>
            <IconOfPlus />
                הוסף אדמין
            </button>
            
            <div className="admin-cards-container">
                {admins.map((admin) => (
                    <AdminItem
                        key={admin.id}
                        admin={admin}
                        onEdit={() => handleOpenDialog(admin)}
                        onDelete={handleAdminUpdated}
                    />
                ))}
            </div>

            <AddOrEditAdmin
                open={openDialog}
                admin={selectedAdmin}
                editing={editingAdmin}
                onClose={handleCloseDialog}
                onUpdate={handleAdminUpdated}
            />
           
        </>
    );
};

export default AllAdminCards;
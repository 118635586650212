import axios from 'axios';
import * as actionType from '../redux/action';
import { GET_TESTS, ADD_TEST, EDIT_TEST, DELETE_TEST, GET_AVERAGE_SCORES, GET_TEST_RESULTS } from '../redux/action';
import { type } from '@testing-library/user-event/dist/type';

const baseUrl = process.env.REACT_APP_SERVER_BASE_URL

export const fetchTests = () => async dispatch => {
    try {
        const response = await axios.get(`${baseUrl}/Test`);
        console.log(response.data); // בדוק את הפלט
        dispatch({ type: GET_TESTS, payload: response.data });
    } catch (error) {
        console.error(error);
    }
};

export const addTest = (testData) => async dispatch => {
    try {
        const response = await axios.post(`${baseUrl}/Test`, testData);
        dispatch({ type: ADD_TEST, payload: response.data });
    } catch (error) {
        console.error(error);
    }
};
export const editTest = (id, testData) => async dispatch => {
    try {
        const response = await axios.put(`${baseUrl}/Test/${id}`, testData);
        dispatch({ type: EDIT_TEST, payload: response.data });
    } catch (error) {
        console.error(error);
    }
};

export const deleteTest = (id) => async dispatch => {
    try {
        await axios.delete(`${baseUrl}/Test/${id}`);
        dispatch({ type: DELETE_TEST, payload: id });
    } catch (error) {
        console.error(error);
    }
};

export const fetchAverageScores = (testId) => async dispatch => {
    try {
        const response = await axios.get(`${baseUrl}/Test/${testId}/average-scores`);
        dispatch({ type: GET_AVERAGE_SCORES, payload: { testId, ...response.data } });
    } catch (error) {
        console.error(error);
    }
};

export const fetchTestResults = (testId) => async dispatch => {
    try {
        const response = await axios.get(`${baseUrl}/Test/${testId}/results`);
        dispatch({ type: GET_TEST_RESULTS, payload: { testId, countOfExaminees: response.data.length } });
    } catch (error) {
        console.error(error);
    }
};

export const updateTestResult = async (testId, userId, updatedResult) => {
    try {
        const response = await axios.put(`${baseUrl}/Test/${testId}/results/${userId}`, updatedResult);
        return response.data;
    } catch (error) {
        console.error('Failed to update test result:', error);
    }
};

export const getAverageScores = async (testId) => {
    try {
        const response = await axios.get(`${baseUrl}/Test/average-scores/${testId}`);
        return response.data;
    } catch (error) {
        console.error("Error fetching average scores:", error);
        return null;
    }
};

export const getIdenticalQuestionIndexes = async (testId, version1, version2) => {
    try {
        const response = await axios.post(`${baseUrl}/Test/identicalIndexes`, {
            testId,
            version1,
            version2
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching identical question indexes:', error);
        throw error;
    }
};

export const updateSelectedAnswer = async (questionId, selectedAnswer) => {
    try {
        const response = await axios.put(`${baseUrl}/Test/update-answer`, { questionId, selectedAnswer });
        return response.data;
    } catch (error) {
        console.error('Error fetching identical question indexes:', error);
        throw error;
    }
};

export const updateQuestionIndexes = async (requestData) => {
    try {
        const response = await axios.post(`${baseUrl}/Test/update-question-indexes`, requestData);
        return response.data;
    } catch (error) {
        console.error('Error updating question indexes:', error);
        throw error;
    }
};
export const getQuestionIndexes = async (testId, typeOfExamId) => {
    try {
        const response = await axios.get(`${baseUrl}/Test/get-question-indexes?testId=${testId}&typeOfExamId=${typeOfExamId}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching question indexes:', error);
        throw error;
    }
};

export const getQuestionIndexesByVersion = async (testId, typeOfExamId, numOfVersion) => {
    try {
        const response = await axios.get(`${baseUrl}/Test/GetQuestionIndexesByVersion`, {
            params: {
                testId,
                typeOfExamId,
                numOfVersion,
            },
        });
        return response.data;

    } catch (error) {
        console.error('Error fetching question indexes:', error);
        throw error;
    }
};

export const updateCorrectAnswers = async (data) => {
    try {
        const response = await axios.post(`${baseUrl}/Test/update-correct-answers`, data, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return response.data; // מחזיר את התגובה מהשרת
    } catch (error) {
        console.error('Error updating answers:', error);
        throw error; // מאפשר טיפול בשגיאות בקומפוננטה
    }
};

export const getExamineesByTestId = async (testId) => {
    try {
        const response = await axios.get(`${baseUrl}/Test/examinees/${testId}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching examinees:', error);
        throw error;
    }
};

// בקשה לעדכון ציונים לנבחן מסוים
export const updateScores = async (payload) => {
    try {
        await axios.post(`${baseUrl}/Test/update-scores`, payload);
    } catch (error) {
        console.error('Error updating scores:', error);
        throw error;
    }
};

export const getEndScore = async (userId, testId) => {
    try {
        const response = await axios.get(`${baseUrl}/Test/${testId}/GetEndScore/${userId}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching end score:', error);
        throw error; // אפשר להוסיף טיפול בשגיאות לפי הצורך
    }
};

export const setVersion = (version) => (
    {
        type: actionType.SET_SELECTED_VERSION,
        payload: version,
    }
);
export const getCorrectAnswers = async (testId, typeOfExamId) => {

    try {
        const response = await axios.get(`${baseUrl}/Test/get-correct-answers`, {
            params: {
                testId,
                typeOfExamId
            },
        });
        return response.data; // מחזיר את התשובה מהשרת
    } catch (error) {
        console.error('Error fetching correct answers:', error);
        throw error; // מאפשר טיפול בשגיאות בקומפוננטה
    }
};
// TestService.js

export const getQuestionStatistics = async (testId, typeOfExamId) => {
    try {
        const response = await axios.get(`${baseUrl}/Test/question-statistics?testId=${testId}&typeOfExamId=${typeOfExamId}`);
        return response.data
    } catch (error) {
        console.error('Error fetching question statistics:', error);
        throw error;
    }
};


export const updateSwitchValue = (settingName,newValue) => async (dispatch) => {
    try {
        await axios.put(`${baseUrl}/Test/UpdateSwitch/${settingName}?newValue=${newValue}`);
        dispatch(fetchSwitchValue(settingName)); // Refresh the switch value after updating
    } catch (error) {
        console.error('Error updating switch value:', error);
    }
};

export const fetchSwitchValue = (settingName) => async dispatch => {
    try {
        const response = await axios.get(`${baseUrl}/Test/GetSwitch/${settingName}`);
        dispatch({ type: actionType.SET_SWITCH_VALUE, payload: response.data });
    } catch (error) {
        console.error('Error fetching switch value:', error);
    }
};
export const fetchIsActiveApp = (settingName) => async dispatch => {
    try {
        const response = await axios.get(`${baseUrl}/Test/GetSwitch/${settingName}`);
        dispatch({ type: actionType.SET_IS_ACTIVE_APP, payload: response.data });
    } catch (error) {
        console.error('Error fetching switch value:', error);
    }
};


export const deleteExamineeOfTest = async (testId, userId) => {
    try {
        const response = await axios.delete(`${baseUrl}/Test/DeleteTestResult?testId=${testId}&userId=${userId}`);
        return response.data;
    } catch (error) {
        console.error('Error deleting user:', error);
        throw error;
    }
};

export const saveMessage = async (title, message) => {
    try {
      const response = await axios.post(`${baseUrl}/SendSms/saveMessage`, {
        title,
        message,
      });
  
      return response.data; // חזרת התגובה מהשרת
    } catch (error) {
      console.error('Error saving message:', error);
      throw error.response?.data || error.message || 'Failed to save message.';
    }
  };

  export const getCommentsByQuestionId = async (testId, typeOfExamId, version1Index, userId, permissionId) => {
    try {
      // קריאה לשרת עם הפרמטרים הנוספים
      const response = await axios.get(`${baseUrl}/Test/GetCommentByQuestion`, {
        params: {
          TestId: testId,
          typeOfExamId: typeOfExamId,
          version1Index: version1Index,
          userId: userId,            // הוספת userId
          permissionId: permissionId  // הוספת permissionId
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching comments:', error);
      throw error;
    }
  };
  

export const addCommentToQuestion = async (testId, typeOfExamId, version1Index, userId, textComment) => {
  try {
    const response = await axios.post(`${baseUrl}/Test/AddComment-question`, {
      TestId: testId,
      typeOfExamId: typeOfExamId,
      version1Index: version1Index,
      UserId: userId,
      TextComment: textComment
    });
    return response.data;
  } catch (error) {
    console.error('Error adding comment:', error);
    throw error;
  }
};

export async function getUserExamQuestionsAndAnswers(testId, userId) {
    try {
        const response = await axios.get(`${baseUrl}/Test/GetUserExamQuestionsAndAnswers`, {
            params: {
                testId: testId,
                userId: userId
            }
        });

        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 404) {
            throw new Error('No questions or answers found for the given test and user.');
        }
        console.error('Error fetching questions and answers:', error);
        throw error;
    }
}

export function convertToCSV(data) {
    const header = ['QuestionType', 'QuestionNumber', 'SelectedAnswer'];
    const rows = data.map(item => [
        item.questionType,
        item.questionNumber,
        item.selectedAnswer
    ]);

    return [header, ...rows].map(row => row.join(',')).join('\n');
}
// src/utils/fileUtils.js

export function downloadCSV(filename, csvContent) {
const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
const link = document.createElement('a');
if (link.download !== undefined) {
    // Feature detection
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', filename);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}
}
import React, { useEffect, useState } from 'react';
import '../../style/SolutionTest.css';
import '../../style/Allpopup.css'
import { FaQuestionCircle } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import downArrowIcon from '../../assets/down-arrow.jpg';

const SolutionTestComponent = () => {
    const logoImage = require('../../assets/easylaw-logo-01.jpg');
    const proceduralIcon = require('../../assets/Notebook.jpg');
    const substantiveIcon = require('../../assets/Notebook Bookmark.jpg');




    const [showPopupCorrected, setShowPopupCorrected] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [showPopup2, setShowPopup2] = useState(false);
    const [currentTest, setCurrentTest] = useState(1); // מצב המבחן הנוכחי
    const questions = Array.from({ length: 40 }, (_, i) => i + 1);
    const [answers, setAnswers] = useState(Array(questions.length).fill(null));
    const [versionInfo, setVersionInfo] = useState({
        proceduralVersion: null,
        substantiveVersion: null
    });
    const [showPopupWhenFinishprocedural, setShowPopupWhenFinishprocedural] = useState(false)
    const navigate = useNavigate();

    // const handleAnswerClick = (questionIndex, answer) => {
    //     if ((currentTest === 1 && !versionInfo.proceduralVersion) || (currentTest === 2 && !versionInfo.substantiveVersion)) {
    //         setShowPopup(true); 
    //         return;
    //     }
    //     const newAnswers = [...answers];
    //     if (newAnswers[questionIndex] === answer) {
    //         newAnswers[questionIndex] = null;
    //     } else if (newAnswers[questionIndex] === null) {
    //         newAnswers[questionIndex] = answer;
    //     }
    //     setAnswers(newAnswers);
    // };
    useEffect(() => {
        if (currentTest === 1 && !versionInfo.proceduralVersion) {
            setShowPopup(true); // שאלון דיוני - פתח פופאפ אם לא נבחרה גרסה
        } else if (currentTest === 2 && !versionInfo.substantiveVersion) {
            setShowPopup(true); // שאלון מהותי - פתח פופאפ אם לא נבחרה גרסה
        }
    }, [currentTest, versionInfo]);

    // const handleAnswerClick = (questionIndex, answer) => {
    //     if ((currentTest === 1 && !versionInfo.proceduralVersion) || (currentTest === 2 && !versionInfo.substantiveVersion)) {
    //         setShowPopup(true); 
    //         return;
    //     }

    //     const newAnswers = [...answers];
    //     newAnswers[questionIndex] = answer; // עדכון תשובה שנבחרה ללא ביטול ידני
    //     setAnswers(newAnswers);
    // };
    const handleAnswerClick = (questionIndex, answer) => {
        if ((currentTest === 1 && !versionInfo.proceduralVersion) || (currentTest === 2 && !versionInfo.substantiveVersion)) {
            setShowPopup(true);
            return;
        }

        const newAnswers = [...answers];
        newAnswers[questionIndex] = answer; // עדכון תשובה שנבחרה
        setAnswers(newAnswers.map((ans, idx) => idx === questionIndex ? answer : ans === 'red' ? null : ans)); // הסרת סימון אדום
    };

    const updateProceduralVersion = (newVersion) => {

        setVersionInfo((prevInfo) => ({
            ...prevInfo,
            proceduralVersion: newVersion
        }));
    };

    const updateSubstantiveVersion = (newVersion) => {
        setVersionInfo((prevInfo) => ({
            ...prevInfo,
            substantiveVersion: newVersion
        }));
    };

    const handleSelectVersion = (version) => {
        if (currentTest === 1) {
            updateProceduralVersion(version);
            setShowPopup(false);
        }
        else {
            updateSubstantiveVersion(version);
            setShowPopup(false);
        }

    };



    // const handleNextTest = () => {
    //     if (currentTest === 1) {
    //         setShowPopupWhenFinishprocedural(true)
    //     } else {
    //         handleSubmit();
    //     }
    // };

    const handleNextTest = () => {
        const answeredQuestions = answers.filter(answer => answer !== null).length;
        const allQuestionsAnswered = answeredQuestions === questions.length;
        const noQuestionsAnswered = answeredQuestions === 0;

        if (!allQuestionsAnswered && !noQuestionsAnswered) {
            // סימון שאלות שלא נענו באדום
            const unansweredIndices = answers.map((answer, index) => answer === null ? index : null).filter(index => index !== null);
            alert("יש להשלים את כל התשובות לפני המעבר לשאלון הבא.");
            setAnswers(prevAnswers => prevAnswers.map((answer, index) => unansweredIndices.includes(index) ? 'red' : answer));
        } else {
            if (currentTest === 1) {
                setShowPopupWhenFinishprocedural(true); // מעבר לשאלון מהותי
            } else {
                handleSubmit(); // שליחת המבחן
            }
        }
    };

    // const handleNextTest = () => {
    //     const answeredQuestions = answers.filter(answer => answer !== null).length;
    //     const allQuestionsAnswered = answeredQuestions === questions.length;
    //     const noQuestionsAnswered = answeredQuestions === 0;

    //     // בדיקה אם ענה על כל השאלות או שלא ענה על אף שאלה
    //     if (!allQuestionsAnswered && !noQuestionsAnswered) {
    //         setShowPopupCorrected(true); // הצגת פופאפ שמבקש לענות על כל השאלות
    //     } else {
    //         if (currentTest === 1) {
    //             setShowPopupWhenFinishprocedural(true);
    //         } else {
    //             handleSubmit();
    //         }
    //     }
    // };

    const handleSubmit = () => {
        const allQuestionsAnswered = answers.every(answer => answer !== null);
        if (!allQuestionsAnswered) {
            alert("יש להשלים את כל השאלות לפני השליחה.");
            return;
        }
        const answersData = {
            test1: currentTest === 1 ? answers : JSON.parse(localStorage.getItem('discussionQuestions')),
            test2: currentTest === 2 ? answers : null
        };
    };

    if (currentTest === 1) {
        localStorage.setItem('discussionQuestions', JSON.stringify(answers));
        localStorage.setItem('versionOfDiscussion', versionInfo.proceduralVersion)
    }
    else if (currentTest === 2) {
        localStorage.setItem('substantiveQuestions', JSON.stringify(answers))
        localStorage.setItem('versionOfSubstantive', versionInfo.substantiveVersion)
    }

    const handleOpenPopupVersion = () => {
        const answeredQuestions = answers.filter(answer => answer !== null).length;
        const allQuestionsAnswered = answeredQuestions === questions.length;
        const noQuestionsAnswered = answeredQuestions === 0;

        if (!allQuestionsAnswered && !noQuestionsAnswered) {
            // סימון שאלות שלא נענו באדום
            const unansweredIndices = answers.map((answer, index) => answer === null ? index : null).filter(index => index !== null);
            alert("יש להשלים את כל התשובות לפני השליחה  .");
            setAnswers(prevAnswers => prevAnswers.map((answer, index) => unansweredIndices.includes(index) ? 'red' : answer));
        } else {
            setShowPopup2(true);
        }
      
    };

    const handleConfirm = () => {
        console.log("Exams sent to the DB");
        setShowPopup2(false);
        navigate('/register');
    };

    const handleReturn = () => {
        setShowPopup2(false);
    };
    const answerMapping = {
        1: 'א',
        2: 'ב',
        3: 'ג',
        4: 'ד',
    };

    return (
        <div className="container">
            <div className="frame21-s-t">
                {showPopup && (
                    <div className="popup-overlay-1">
                        <PopupVersion
                            onClose={() => setShowPopup(false)}
                            onSelectVersion={handleSelectVersion}
                            versionInfo={versionInfo}
                            setVersionInfo={setVersionInfo}
                            currentTest={currentTest}
                            setShowPopup={setShowPopup}
                        />
                    </div>
                )}
                <img className="easylaw-logo" src={logoImage} alt="Logo" />
                <div className="frame14-title">
                    <div className="title-begin">{currentTest === 1 ? 'דין דיוני' : 'דין מהותי'}<img className='book-icon' src={(currentTest === 1) ? proceduralIcon : substantiveIcon} /></div>
                    <div className="input-field-base">
                        <div className="input-with-label">
                            <div className="input">
                                <div className="dropdown">
                                    <select
                                        style={{
                                            appearance: 'none',
                                            backgroundImage: `url(${downArrowIcon})`,
                                            backgroundRepeat: 'no-repeat',
                                            backgroundPosition: 'left 10px center',
                                            backgroundSize: '12px 12px',
                                            paddingLeft: '30px',
                                        }}
                                        className="custom-select"
                                        value={(currentTest === 1) ? versionInfo.proceduralVersion : versionInfo.substantiveVersion || 'בחרו גרסה'}
                                        onChange={(e) => handleSelectVersion(e.target.value)}
                                    >
                                        <option value="">בחרו גרסה</option>
                                        <option value="1">גרסה 1</option>
                                        <option value="2">גרסה 2</option>
                                        <option value="3">גרסה 3</option>
                                        <option value="4">גרסה 4</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="frame13">
                <div className="frame16">
                    <div className="frame14">
                        <div className="title">
                            מה סימנת בבחינה?
                        </div>
                        <div className="subtitle">
                            <div>יש ללחוץ על התשובה שסימנת כנכונה בבחינה.</div>
                        </div>
                    </div>
                    <div className="important-notice">חשוב! ניתן להזין תשובות פעם אחת בלבד.</div>
                </div>
                <div className="frame1707478352">
                    <div className="frame15" >
                        <div className="solution-list">
                            {questions.map((item, index) => (
                                <div key={item} className="solution-item">
                                    <div className="answers" style={{ display: 'flex', justifyContent: 'flex-end', direction: 'rtl' }}>
                                        {[1, 2, 3, 4].map((number) => (
                                            <div
                                                key={number}
                                                className={`answer-option ${answers[index] === number ? 'selected' : ''}`}
                                                onClick={() => handleAnswerClick(index, number)}
                                                // style={{ marginLeft: '10px' }}
                                            >
                                                {answerMapping[number]}
                                            </div>
                                        ))}
                                    </div>

                                    <div className="item-number">.{item}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className='TwoButtons'>
                    {currentTest === 1 ? (
                        <button className="buttonApprove" onClick={handleNextTest}>המשך לדין המהותי</button>
                    ) : (
                        <button className="buttonApprove" onClick={handleOpenPopupVersion}>אישור ושליחה</button>
                    )}
                    <button className="CancelButton" onClick={() => setAnswers(Array(questions.length).fill(null))}>ביטול והזנה מחדש</button>
                    {showPopup2 && (
                        <div className='border-topopup'>
                            <PopupComponent

                                onClose={() => setShowPopup2(false)}
                                onConfirm={handleConfirm}
                                handleReturn={handleReturn}
                                versionInfo={versionInfo}
                                setVersionInfo={setVersionInfo}
                                showPopupCorrected={showPopupCorrected}
                            />
                        </div>
                    )}
                    {showPopupWhenFinishprocedural && <div className='showPopupToGetPartTwo'><PopupMessageAfterConfirmation setCurrentTest={setCurrentTest} setAnswers={setAnswers} questions={questions} setShowPopupWhenFinishprocedural={setShowPopupWhenFinishprocedural} versionInfo={versionInfo} /></div>}
                </div>
            </div>

        </div>
    );
};

const PopupComponent = ({ onConfirm, handleReturn, versionInfo, setVersionInfo, showPopupCorrected }) => {

    return (
        <div className="window-popup2" >
            <div className="Title-popup2" >
                לתשומת ליבך
            </div>
            <div className="Title2-popup2" >
                <div className='con-popup2'>ניתן להזין תשובות פעם אחת בלבד.</div>
            </div>
            <div className="versionsDiv-popup2">
                <div className="diss-popup2">גירסה בדין הדיוני: {versionInfo.proceduralVersion || ''}
                    <br />גירסה בדין המהותי: {versionInfo.substantiveVersion || ''}
                </div>
                <div className='sure-popup2'>יש לוודא מספר גירסה לפני השליחה</div>
            </div>
            <div className="Frame22-popup2">
                <div className="btn1-popup2" onClick={onConfirm}>אישור</div>
                <div className="btn5-popup2" onClick={handleReturn}>חזרה</div>
            </div>
        </div>
    );
};



const PopupVersion = ({ onClose, onSelectVersion, versionInfo, setVersionInfo, currentTest, setShowPopup }) => {
    const [selectedVersion, setSelectedVersion] = useState(null);
    const [versionChoose, setVersionChoose] = useState(null);
    const updateProceduralVersion = async (newVersion) => {
        await setVersionInfo((prevInfo) => ({
            ...prevInfo,
            proceduralVersion: newVersion
        }));
        await setSelectedVersion(versionInfo.proceduralVersion);
    };

    const updateSubstantiveVersion = (newVersion) => {
        setVersionInfo((prevInfo) => ({
            ...prevInfo,
            substantiveVersion: newVersion
        }));
        setSelectedVersion(versionInfo.substantiveVersion);
    };
    const handleVersionClick = (version) => {
        if (currentTest === 1) {
            updateProceduralVersion(version);
        }
        else {
            updateSubstantiveVersion(version);
        }

    };



    const handleConfirmClick = () => {
        if (currentTest === 1 && versionInfo.proceduralVersion) {
            onSelectVersion(versionInfo.proceduralVersion);
            setShowPopup(false);
        }
        else if (currentTest === 2 && versionInfo.substantiveVersion) {
            onSelectVersion(versionInfo.substantiveVersion);
            setShowPopup(false);
        }
    };
    const versionMapping = {
        1: 'גרסה 1',
        2: 'גרסה 2',
        3: 'גרסה 3',
        4: 'גרסה 4',
    };

    if ((currentTest === 1) || (currentTest === 2))
        return (
            <div className="popup">
                <div className="popup-content">
                    <div className="HeaderContainer">
                        <div className="HeaderTextContainer">
                            <div className="Title">גירסת מבחן</div>
                            <div className="Subtitle">{currentTest === 1 ? 'יש לבחור את גירסת המבחן שלך בדין דיוני' : 'יש לבחור את גירסת המבחן שלך בדין מהותי'}</div>
                        </div>
                    </div>
                    <div className="OptionsContainer">
                        <div className="Options">
                            {[4, 3, 2, 1].map((version, index) => (
                                <button
                                    key={index}
                                    onClick={() => (handleVersionClick(version), setVersionChoose(version))}
                                    className={`ButtonColorPicker ${versionChoose === version ? 'Selected' : ''}`}
                                    style={{
                                        background: versionChoose === version ? 'rgb(240,250,253)' : 'white',
                                        border: versionChoose === version ? '1px #202341 solid' : '1px #D0D5DD solid',
                                        color: versionChoose === version ? '#202341' : 'black'
                                    }}

                                >
                                    {versionMapping[version]} </button>
                            ))}

                        </div>
                        <button className="SubmitButton" onClick={handleConfirmClick} style={{ background: versionChoose ? '#21FFF5' : '#D0D5DD' }}>אישור</button>
                    </div>
                </div>
            </div>
        );
};




const PopupMessageAfterConfirmation = ({ setCurrentTest, setAnswers, questions, setShowPopupWhenFinishprocedural, versionInfo }) => {
    const dropdown = require('../../assets/Dropdown Icon.jpg');

    const handleSendAnswer = () => {
        // מעבר למבחן השני
        setCurrentTest(2);
        setAnswers(Array(questions.length).fill(null));
        setShowPopupWhenFinishprocedural(false);

    }

    const handleReturn = () => {

        setShowPopupWhenFinishprocedural(false);
    }


    return (
        <div className="popup-container-p-m-a-c">
            <div className="frame-content-p-m-a-c">
                <div className="frame-title-p-m-a-c">
                    <div className="title-p-m-a-c">
                        <div>לתשומת ליבך</div>
                    </div>
                </div>
                <div className="title message-box-p-m-a-c">
                    <div>ניתן להזין תשובות פעם אחת בלבד.</div>
                </div>
                <div className="title version-check-p-m-a-c">
                    <div className="version-text-p-m-a-c">גרסה בדין הדיוני: {versionInfo.proceduralVersion}</div>
                    <div className='version-p-m-a-c'>יש לוודא מספר גירסה לפני השליחה</div>
                </div>
            </div>
            <div className="frame-actions-p-m-a-c" >
                <div className="button-container-p-m-a-c" onClick={handleSendAnswer}>
                    <div className="button-confirm-p-m-a-c">
                        <div>אישור</div>
                    </div>
                </div>
                <div className="button-container-p-m-a-c" onClick={handleReturn}>
                    <div className="button-back-p-m-a-c">
                        <div>חזרה</div>
                        <img src={dropdown} className="icon-alt-arrow-right-p-m-a-c" />
                    </div>
                </div>
            </div>
        </div>
    );
};




export default SolutionTestComponent;


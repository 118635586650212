

import React from 'react';
import '../../style/finishWindow.css';
import { useLocation } from 'react-router-dom';


const FinishWindow = () => {
  const logoImage = require('../../assets/easylaw-logo-01.jpg');
  const star = require('../../assets/OBJECTS.jpg')
  const location = useLocation();
  const { isSolutionReady } = location.state


  return (
    <div className="container-f-w">
      <div className="frame21-f-w">
        <img className="easylawLogo-f-w" src={logoImage} alt="Easylaw Logo" />

      </div>
      {/* <div className="objects-f-w"> */}
        <img src={star} className="vector-f-w" />
      {/* </div> */}
      <div className="frame1707478413-f-w">
          <div className="largeText-f-w">
            {isSolutionReady ? 'הציונים מוכנים!' : 'אנחנו על זה'}
          </div>
      </div>
      {/* <div className="title-f-w"> */}
        {isSolutionReady ? (
          <div className="easylawSms--f-w">
            הצוות המקצועי של EASYLAW סיים את פתרון הבחינה.<br />
            נא הכנס ללינק שנשלח לך ב-SMS לצפייה בציון המשוער.
          </div>
        ) : (
          <div className="easylawSms--f-w">
            הצוות המקצועי של EASYLAW פותר ברגעים אלה את הבחינה.
            <br />
            ברגע שהפתרון יסתיים נשלח לך הודעת SMS עם לינק לצפייה בציון המשוער.
          </div>
        )}
      {/* </div> */}

    </div>
  );
};

export default FinishWindow;
